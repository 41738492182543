import { Box, Divider, FormControl, InputLabel, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { ServiceIcon } from 'src/components/icons/ServiceIcon';
import {
  RestaurantShiftTimesOption,
  RestaurantShiftTimesOptionType,
} from './models/RestaurantShiftTimesOption';
import { RestaurantShiftTimesOptions } from './hooks/useRestaurantShiftTimesOptions';
import { useNavigate } from 'react-router-dom';
import { venueSettingsShiftPath } from 'src/app/navigation/pathHelpers';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { SettingsOutlined } from '@mui/icons-material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

const menuItem = (selectedShift: RestaurantShiftTimesOption) => (
  <MenuItem value={selectedShift.type}>
    <Box>
      <Typography>{selectedShift.label}</Typography>
      <Typography style={{ color: '#70707B' }}>
        {selectedShift.timeRangeLabel}
      </Typography>
    </Box>
  </MenuItem>
);

export const RestaurantShiftPicker = ({
  value,
  onChange,
  options,
}: {
  value: RestaurantShiftTimesOption;
  onChange: (change: RestaurantShiftTimesOption) => void,
  options: RestaurantShiftTimesOptions
}) => {
  const navigate = useNavigate();
  const business = useCurrentBusinessOrThrow();

  const restaurantShiftsDefinition = Object.values(options);

  const openShiftSettings = () => {
    navigate(`${venueSettingsShiftPath(business.id)}`);
  };

  const changeShift = (event: SelectChangeEvent) => {
    const key = event.target.value as RestaurantShiftTimesOptionType;
    onChange(options[key]);
  };

  return (
    <FormControl>
      <InputLabel id="service-label">
        <Typography>Service</Typography>
      </InputLabel>
      <Select
        labelId="service-label"
        label="Service"
        value={value.type}
        onChange={changeShift}
        defaultValue="ALL SERVICES"
        renderValue={(selectedShift: string) => (
          <Box display="flex" gap="5px">
            <ServiceIcon />
            <Typography>{options[selectedShift as RestaurantShiftTimesOptionType].label}</Typography>
          </Box>
        )}
        sx={{ width: 'max-content' }}
      >
        {restaurantShiftsDefinition.map(menuItem)}
        <Box>
          <Divider />
          <MenuItem value="LINK_SHIFT_SETTINGS" onClick={openShiftSettings}>
            <Stack direction="row" spacing={1} alignItems="center">
              <SettingsOutlined sx={{ width: '20px', height: '20px', fill: '#70707B' }}/>
              <Typography>
                <FormattedMessage id="sales_summary.shift.configure" defaultMessage="Configure service hours" />
              </Typography>
            </Stack>
          </MenuItem>
        </Box>
      </Select>
    </FormControl>
  );
};
