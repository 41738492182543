import { Box, Button, CircularProgress, FormControl, InputLabel, Paper, Select, Stack, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';
import { RouteDefinitions } from '../app/RouteDefinitions';
import { useBusinessIdOrThrow } from '../business/hooks/useBusinessId';
import { ConfigurationLoader } from '../configuration/ConfigurationLoader';
import { usePongoSaveStore } from './mutations/usePongoSaveStore';
import { useListPongoStores } from './queries/useListPongoStores';
import { useGetBusinessLoyaltyReference } from './queries/useGetBusinessLoyaltyReference';
import { useSearchParams } from 'react-router-dom';
import { useAuthenticatePongoBusiness } from 'src/loyalty/queries/useAuthenticatePongoBusiness';
import { palette } from 'src/organization-report/design/palette';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { BusinessId } from 'src/business/domain/Business';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';

export const PongoStoreSelector = ({ businessId, isAuthenticated }: {
  businessId: BusinessId;
  isAuthenticated: boolean
}) => {
  const [selectedStoreId, setSelectedStoreId] = useState<string | undefined>();
  const saveStore = usePongoSaveStore(businessId);
  const { formatMessage } = useIntl();
  const snackBar = useSnackbar();

  const {
    data: stores,
    isLoading: storesAreLoading,
  } = useListPongoStores(isAuthenticated);


  if (storesAreLoading) {
    return <CircularProgress />;
  }
  return (
    <>
      {stores && stores.length > 0 && (
        <Stack gap={3}>
          <FormControl>
            <InputLabel id="selectStore">{formatMessage({ id: 'loyalty.onboarding.pongo.select_store' })}</InputLabel>
            <Select
              labelId="selectStore"
              label={formatMessage({ id: 'loyalty.onboarding.pongo.select_store' })}
              value={selectedStoreId}
              onChange={(e) => setSelectedStoreId(e.target.value)}
            >
              {stores.map((store) => (
                <MenuItem value={store.id} key={store.id}>
                  {store.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            disabled={!selectedStoreId}
            variant="contained"
            onClick={() => saveStore.mutateAsync(selectedStoreId!)
              .then(() => {
                snackBar.addNotification({
                  variant: 'success',
                  text: formatMessage({ id: 'loyalty.onboarding.success' }),
                });
              })
              .catch(() => {
                snackBar.addNotification({
                  variant: 'error',
                  text: formatMessage({ id: 'loyalty.onboarding.errors.other' }),
                });
              })
            }
          >
            {formatMessage({ id: 'loyalty.onboarding.save', defaultMessage: 'Save' })}
          </Button>
        </Stack>
      )}

    </>
  );
};
export const LoyaltyOnboardingPongo = () => {
  const [searchParams] = useSearchParams();
  const businessId = useBusinessIdOrThrow();
  const { formatMessage } = useIntl();
  const { data: isAuthenticated, isLoading: authenticationIsLoading } = useAuthenticatePongoBusiness();

  const {
    data: loyaltyBusinessConfiguration,
    isLoading: loyaltyReferenceIsLoading,
  } = useGetBusinessLoyaltyReference(businessId);

  const configuration = ConfigurationLoader.load();
  const redirectUrl = `${configuration.callbackUrl}${RouteDefinitions.PongoLoyaltyOauthRedirect.path}`;

  const scopes = 'reward.read,reward.update,customer.create,order.create,customer.read,customer.update,checkin.create';
  const url = `${configuration.pongoBaseUrl}/oauth/form?client_id=${configuration.pongoClientId}&state=${businessId}&redirect_uri=${redirectUrl}&scope=${scopes}`;
  const code = searchParams.get('code');

  if (loyaltyReferenceIsLoading || authenticationIsLoading) {
    return <CircularProgress />;
  }

  const hasError = code && !isAuthenticated! && !loyaltyBusinessConfiguration;

  return (
    <Paper style={{ borderRadius: '1.5rem', maxWidth: '1000px' }} elevation={0}>
      <Stack margin="24px" width="inherit" gap={3}>
        <Typography variant="h5">
          {formatMessage({ id: 'loyalty.onboarding.title' }, { loyaltyReference: 'Pongo' })}
        </Typography>

        {loyaltyBusinessConfiguration && (
          <Box padding={'8px'}>
            <Typography>
              {formatMessage({ id: 'loyalty.onboarding.connected' }, {
                loyaltyReference: loyaltyBusinessConfiguration.loyaltyReference,
                theme: loyaltyBusinessConfiguration.theme,
              })}
            </Typography>
          </Box>
        )}

        {!loyaltyBusinessConfiguration && !code && (
          <Button
            variant="contained"
            onClick={() => {
              window.location.href = url;
            }}
          >
            {formatMessage({ id: 'loyalty.onboarding.pongo.title' })}
          </Button>
        )}

        {!loyaltyBusinessConfiguration && isAuthenticated && (
          <PongoStoreSelector businessId={businessId} isAuthenticated={isAuthenticated} />
        )}

        {hasError && (
          <Stack gap="16px">
            <Typography color={palette.status.danger}>
              {formatMessage({ id: 'loyalty.onboarding.pongo.error' })}
            </Typography>

            <Button
              variant="contained"
              onClick={() => {
                window.location.href = url;
              }}
            >
              {formatMessage({ id: 'loyalty.onboarding.retry' })}
            </Button>
          </Stack>
        )}
      </Stack>
    </Paper>
  );
};
