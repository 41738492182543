import { Outlet } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSundayIntercom } from 'src/intercom/hooks/useSundayIntercom';
import { useClaimForVenue } from 'src/auth/hooks/useClaimForVenue';
import { RedirectToFirstUserVenue } from '../../navigation/components/RedirectToFirstUserVenue';
import { OrderAndPayRoutingContextProvider } from 'src/order-and-pay/context/OrderAndPayRoutingContextProvider';
import MainLayout from '../../layouts/MainLayout';
import { ampli } from 'src/ampli';
import { useCurrentUser } from 'src/auth/component/UserProvider';
import { AuthenticatedUser } from 'src/auth/domain/user';
import { experiment } from 'src/feature-flipping/infrastructure/experiment';
import { datadogLogs } from '@datadog/browser-logs';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useBusinessQuery } from 'src/business/hooks/useBusinessQuery';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { useSnackbar } from '../../contexts/snackbars/SnackBarContext';
import BusinessInitialSetup from 'src/initial-setup/components/BusinessInitialSetup';
import { hasAccessToMultiLocations } from 'src/multi-locations/domain/User';

const Venue = () => {
  const intl = useIntl();
  const businessId = useBusinessIdOrThrow();
  const [isLoadingFeatureFlags, setIsLoadingFeatureFlags] = useState(false);
  const snackbar = useSnackbar();

  const { data: business, isLoading: isLoadingBusiness } = useBusinessQuery(businessId, {
    retry: (failureCount, error) => {
      if ((error as unknown as { code: string })?.code === 'unavailable') {
        snackbar.addNotification({
          variant: 'info',
          text: intl.formatMessage({
            id: 'venue.network_failure',
            defaultMessage: 'Internet issue, we are still trying to fetch your data.',
          }),
        });
        datadogLogs.logger.error('Failed to get the business because firebase client unavailable', {
          businessId,
          error,
        });
        return true;
      }

      return failureCount !== 6;
    },
  });

  const claim = useClaimForVenue(businessId);
  const currentUser = useCurrentUser() as AuthenticatedUser;

  useEffect(() => {
    setIsLoadingFeatureFlags(true);
    const updateTracking = async () => {
      ampli.identify(currentUser.userId, {
        type: currentUser.userType,
        hasImpersonated: currentUser.impersonated,
        Role: claim?.userRole,
        hasAllLocationsAccess: hasAccessToMultiLocations(currentUser),
      });
      const userProperties = {
        venueId: businessId,
        businessId: businessId,
        businessCountryCode: business?.address.countryCode.toString() ?? '',
        businessName: business?.name ?? '',
      };

      await ampli.userLoggedIn(userProperties).promise;
      await experiment.fetch({ user_properties: { ...userProperties, venueId: businessId } });
      setIsLoadingFeatureFlags(false);
    };

    updateTracking().then(() => undefined);
  }, [businessId, currentUser.userId, claim?.userRole, business]);

  useSundayIntercom(business!, isLoadingBusiness);

  if (!claim) return <RedirectToFirstUserVenue />;
  if (isLoadingBusiness || isLoadingFeatureFlags)
    return (
      <>
        <CircularProgress />
      </>
    );

  if (!business?.isInitialSetupCompleted) return <BusinessInitialSetup />;

  return (
    <MainLayout>
      <OrderAndPayRoutingContextProvider>
        <Outlet />
      </OrderAndPayRoutingContextProvider>
    </MainLayout>
  );
};

export default Venue;
