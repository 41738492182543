import { Business } from 'src/business/domain/Business';
import { queryKeys } from 'src/app/queries/utils';
import {
  EndOfServiceClient,
  PaymentSourceOfDiscrepanciesDTO,
} from 'src/operations/end-of-service/infrastructure/EndOfServiceClient';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import {
  PaymentSourceOfDiscrepancies,
  PaymentsSourceOfDiscrepancies,
} from 'src/operations/end-of-service/model/PaymentSourceOfDiscrepancies';

function toDomain(data: PaymentSourceOfDiscrepanciesDTO): PaymentSourceOfDiscrepancies {
  const timestampInMilliseconds = data.createdAt * 1000;
  return {
    createdAt: new Date(timestampInMilliseconds),
    paymentId: data.paymentId.value,
    sales: data.sales,
    posPooledTips: data.tipsNotifiedToPos,
    digitalFee: data.digitalFee,
    productOrigin: data.productOrigin,
    tableNumber: data.tableNumber,
    waiterName: data.waiterName,
    billCode: data.billCode,
    cardInfos: data.cardInfos && {
      countryCode: data.cardInfos.countryCode,
      brand: data.cardInfos.brand,
      last4: data.cardInfos.last4,
      network: data.cardInfos.network,
      isAmex: data.cardInfos.isAmex,
    },
  };
}

export const paymentsSourceOfDiscrepancies = (business: Business, startDate: Date, endDate: Date) => ({
  queryKey: [
    queryKeys.endOfService.paymentsSourcesOfDiscrepancies,
    business.id,
    business.currency,
    {
      startDate,
      endDate,
    },
  ],
  queryFn: async (): Promise<PaymentsSourceOfDiscrepancies> => {
    const reconciliationClient = new EndOfServiceClient(axios);
    const data = await reconciliationClient.getPaymentsSourceOfDiscrepancies(business.id, startDate, endDate);
    if (!data) return { nonNotifiedPayments: [], fastPaymentPdq: [] };
    return {
      nonNotifiedPayments: data?.nonNotifiedPayments.map(toDomain),
      fastPaymentPdq: data?.fastPaymentPdq.map(toDomain),
    };
  },
});

export const usePaymentsSourceOfDiscrepancies = ({
  business,
  startDate,
  endDate,
}: {
  business: Business;
  startDate: Date;
  endDate: Date;
}) => {
  const query = paymentsSourceOfDiscrepancies(business, startDate, endDate);
  return useQuery<PaymentsSourceOfDiscrepancies>({ queryKey: query.queryKey, queryFn: query.queryFn });
};
