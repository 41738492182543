import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { CrossIcon } from 'src/ordering/common/components/Icons';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { AccessTime, Money } from '@mui/icons-material';
import { DispatchStrategy } from 'src/tips/dispatch/model/DispatchStrategy';

const DistributionStrategyView = ({
  icon,
  title,
  description,
}: {
  icon: React.ReactNode;
  title: React.ReactNode;
  description: React.ReactNode;
}) => {
  return (
    <Stack direction="row" alignItems="center" gap={2}>
      {icon}
      <Stack direction="column" gap={1}>
        <Typography variant={'body1'} fontWeight={500}>
          {title}
        </Typography>
        <Typography variant={'body2'} color={'textSecondary'}>
          {description}
        </Typography>
      </Stack>
    </Stack>
  );
};

type Props = {
  onClose: () => void;
  onStrategySelected: (strategy: DispatchStrategy) => void;
};

export const SelectDispatchStrategy = ({ onClose, onStrategySelected }: Props) => {
  const theme = useTheme();
  const radioButtonControlStyle = {
    padding: '0.5rem',
    border: '1px solid',
    borderRadius: '16px',
    borderColor: theme.palette.secondary.light,
    '&:has(.Mui-checked)': {
      borderColor: theme.palette.primary.main,
      backgroundColor: 'rgba(255, 23, 233, 0.04)',
    },
  };
  const [selectedStrategy, setSelectedStrategy] = React.useState<DispatchStrategy>('byAmounts');

  const onClick = () => onStrategySelected(selectedStrategy);

  return (
    <Stack sx={{ background: 'white', height: '100%', overflow: 'auto', paddingBottom: '1rem' }} flexDirection="column">
      <Stack direction="row" justifyContent="space-between" alignItems={'center'} padding="1rem 1.5rem 1rem 1.5rem">
        <Stack sx={{ cursor: 'pointer' }} onClick={onClose}>
          <CrossIcon />
        </Stack>
        <Stack alignItems={'center'}>
          <Typography>
            <FormattedMessage id={'tips.sunday_pooling.dispatch.title'} />
          </Typography>
        </Stack>
        <Stack alignItems={'flex-end'}>
          <Button size="small" variant="contained" onClick={onClick}>
            <FormattedMessage id={'tips.sunday_pooling.dispatch.continue'} />
          </Button>
        </Stack>
      </Stack>

      <Stack
        sx={{ width: '100%', height: '100%', overflow: 'auto' }}
        padding="3rem 0 3rem 0"
        direction="column"
        justifyContent={'flex-start'}
        alignItems={'center'}
      >
        <Stack width="auto" height="100%" justifyContent={'space-between'} spacing={3}>
          <Stack direction="column" spacing={3}>
            <Typography variant={'h4'}>
              <FormattedMessage id={'tips.dispatch.strategy_selection.title'} />
            </Typography>
            <Box>
              <FormControl>
                <RadioGroup
                  defaultValue="byAmounts"
                  name="strategy-selection"
                  onChange={(e) => setSelectedStrategy(e.target.value as DispatchStrategy)}
                >
                  <Stack direction="column" spacing={2}>
                    <FormControlLabel
                      sx={radioButtonControlStyle}
                      value="byAmounts"
                      control={<Radio />}
                      label={
                        <DistributionStrategyView
                          icon={<Money fontSize="large" />}
                          title={<FormattedMessage id={'tips.dispatch.strategy_selection.by_amount.title'} />}
                          description={<FormattedMessage id={'tips.dispatch.strategy_selection.by_amount.sub_title'} />}
                        />
                      }
                    />
                    <FormControlLabel
                      sx={radioButtonControlStyle}
                      value="byHours"
                      control={<Radio />}
                      label={
                        <DistributionStrategyView
                          icon={<AccessTime fontSize="large" />}
                          title={<FormattedMessage id={'tips.dispatch.strategy_selection.by_hours.title'} />}
                          description={<FormattedMessage id={'tips.dispatch.strategy_selection.by_hours.sub_title'} />}
                        />
                      }
                    />
                  </Stack>
                </RadioGroup>
              </FormControl>
            </Box>
            <Button size="small" variant="contained" onClick={onClick}>
              <FormattedMessage id={'tips.sunday_pooling.dispatch.continue'} />
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
