import {
  getExposedProductName,
  PosConnection,
  PricingStrategy,
  Product,
  ProductType,
  translationByProductType,
} from 'src/menu/dynamic-menu/domain/Product';
import { Money, renderMoney } from '@sundayapp/web-money';
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Box, Chip, Typography } from '@mui/material';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { ImgContainer } from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/ProductImageContainer';
import { ProductImage } from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/MenuImage';
import { LinkExternalIcon } from 'src/app/component/icons/LinkExternalIcon';
import { venueMenuProductPath } from 'src/app/navigation/pathHelpers';
import { Link } from 'react-router-dom';
import { BusinessId } from 'src/business/domain/Business';
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { CSS } from '@dnd-kit/utilities';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { DragIndicator } from '@mui/icons-material';
import { SourceIcon } from 'src/menu/common/SourceIcon';
import { ProductPromotionsList } from 'src/menu/dynamic-menu/pages/ProductPage/components/ProductPromotionsList';

type ProductInfo = {
  id: string;
  name: string;
  partnerName?: string;
  pictureUrl: string;
  type: ProductType;
  price: Money;
  posConnection?: PosConnection;
  pricingStrategies?: PricingStrategy[];
};

const ProductListItem: FC<{
  product: ProductInfo;
  isLast: boolean;
  enableNavigate: { businessId: BusinessId } | undefined;
}> = ({ product, isLast, enableNavigate }) => {
  const intl = useIntl();

  return (
    <Box display="flex" key={product.id} flexDirection="column" width="100%">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        sx={{
          borderBottom: `1px solid ${isLast ? 'unset' : themeV5.palette.divider}`,
        }}
      >
        <Box display="flex" gap={2} alignItems="center">
          <Box pb={2} pt={2}>
            <ImgContainer style={{ width: '32px', height: '32px' }}>
              {product.pictureUrl && <ProductImage $imageUrl={product.pictureUrl} />}
            </ImgContainer>
          </Box>

          <Typography variant="body2">{getExposedProductName(product)}</Typography>

          <Chip color={'secondary'} label={<FormattedMessage id={translationByProductType[product.type].single} />} />
          <SourceIcon isSunday={!product.posConnection} />
        </Box>

        <Box display="flex" gap={2} alignItems="center">
          <Typography variant="body2">{renderMoney(product.price, intl.locale)}</Typography>

          {enableNavigate && (
            <Link
              to={venueMenuProductPath(enableNavigate.businessId, product.id)}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <LinkExternalIcon />
            </Link>
          )}
        </Box>
      </Box>
      <Box paddingLeft={'48px'} paddingRight={'42px'}><ProductPromotionsList pricingStrategies={product.pricingStrategies || []} nested={true} /></Box>
    </Box>
  );
};

const SortableItem: FC<{ sortingId: string; enabled?: boolean } & PropsWithChildren> = ({
  sortingId,
  enabled,
  children,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: sortingId });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const lineHeight = 64;
  const dragIconSize = 20;
  const dragIconPaddingTop = lineHeight / 2 - dragIconSize / 2;
  return (
    <div ref={setNodeRef} {...attributes} style={style}>
      <Box display="flex" alignItems="flex-start" gap={2} sx={{ background: themeV5.palette.background.paper }}>
        {enabled && (
          <Box style={{ paddingTop: `${dragIconPaddingTop}px` }}>
            <DragIndicator
              fontSize="large"
              {...listeners}
              style={{ cursor: 'grab', opacity: '0.3', width: `${dragIconSize}px`, height: `${dragIconSize}px` }}
            />
          </Box>
        )}
        {children}
      </Box>
    </div>
  );
};

export const ProductSimpleList: FC<{
  products: ProductInfo[];
  enableNavigate?: { businessId: BusinessId };
  emptyState?: ReactNode;
  onSortChanged?: (productIds: Product['id'][]) => void;
}> = ({ products, enableNavigate, emptyState, onSortChanged }) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEnd(event: any) {
    if (!onSortChanged) {
      return;
    }

    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = products!.findIndex((e) => e.id === active.id);
      const newIndex = products!.findIndex((e) => e.id === over.id);
      const newOrderedElements = arrayMove(products, oldIndex, newIndex);
      onSortChanged(newOrderedElements.map((e) => e.id));
    }
  }

  if (!products.length) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" p={4}>
        <Typography variant="subtitle2" color={themeV5.palette.text.secondary} sx={{ textAlign: 'center' }}>
          {emptyState}
        </Typography>
      </Box>
    );
  }

  const isLastOfList = (product: ProductInfo) => products.map((p) => p.id).indexOf(product.id) === products.length - 1;

  return (
    <Box display="flex" flexDirection="column">
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={products} strategy={verticalListSortingStrategy}>
          {products.map((product) => (
            <SortableItem key={product.id} sortingId={product.id} enabled={products.length > 1 && !!onSortChanged}>
              <ProductListItem product={product} isLast={isLastOfList(product)} enableNavigate={enableNavigate} />
            </SortableItem>
          ))}
        </SortableContext>
      </DndContext>
    </Box>
  );
};
