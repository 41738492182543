import { renderMoney } from '@sundayapp/web-money';
import { Box, Modal, Stack, Theme, Typography, useTheme } from '@mui/material';
import { FormattedMessage, IntlShape, useIntl } from 'src/app/i18n/TypedIntl';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { PaymentMethodCell } from 'src/operations/end-of-service/components/reconciliationHelpCenter/PaymentMethodCell';
import * as React from 'react';
import { GridValidRowModel } from '@mui/x-data-grid/models/gridRows';
import { NoDataOverlay } from 'src/staff-performance/component/NoDataOverlay';
import { CrossIcon } from 'src/ordering/common/components/Icons';
import {
  CardInfos,
  PaymentProductOrigin,
  PaymentSourceOfDiscrepancies,
  productOrigin,
} from 'src/operations/end-of-service/model/PaymentSourceOfDiscrepancies';

interface PaymentsSourceOfDiscrepanciesViewModel extends GridValidRowModel {
  id: string,
  at: { date: string, time: string },
  origin: { paymentProductOrigin: PaymentProductOrigin, tableNumber?: string, billCode?: string },
  server?: string,
  cardInfos?: CardInfos,
  sales: string,
  tips: string,
}

const formatDate = (date: Date) => ({
  date: date.toLocaleDateString(navigator.language, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }),
  time: date.toLocaleTimeString(navigator.language, {
    hour: '2-digit',
    minute: '2-digit',
  }),
});
const toViewModel = (payments: PaymentSourceOfDiscrepancies[], locale: string): PaymentsSourceOfDiscrepanciesViewModel[] =>
  payments.map((payment, index) => {
    const formattedDate = formatDate(payment.createdAt);
    return (
      {
        id: index.toString(),
        at: formattedDate,
        origin: { paymentProductOrigin: payment.productOrigin, tableNumber: payment.tableNumber },
        server: payment.waiterName,
        cardInfos: payment.cardInfos,
        sales: renderMoney(payment.sales, locale),
        tips: renderMoney(payment.posPooledTips, locale),
      }
    );
  },
  );
const getOriginLabel = (origin: PaymentProductOrigin, intl: IntlShape): string => {
  if (origin === productOrigin.PDQ) {
    return intl.formatMessage({ id: 'sales_summary.reconcile.help_center.failed_to_be_notified_operations.table.source.pdq' });
  }
  if (origin === productOrigin.PAT) {
    return intl.formatMessage({ id: 'sales_summary.reconcile.help_center.failed_to_be_notified_operations.table.source.qr_code' });
  } else return 'Support';
};
const buildColumns = (theme: Theme, intl: IntlShape): GridColDef[] => {
  return [
    {
      field: 'at', headerName: 'Date & Time', width: 150,
      disableColumnMenu: true,
      renderHeader: () => (
        <FormattedMessage id="sales_summary.reconcile.help_center.failed_to_be_notified_operations.table.date" />
      ),
      renderCell: ({ row }: { row: PaymentsSourceOfDiscrepanciesViewModel }) => {
        return (
          <Stack alignItems={'flex-start'} justifyContent={'center'} height={'100%'}>
            <Typography>{row.at.time}</Typography>
            <Typography variant={'caption'} color={theme.palette.text.secondary}>{row.at.date}</Typography>
          </Stack>
        );
      },
    },
    {
      field: 'origin', headerName: 'Source', width: 100,
      disableColumnMenu: true,
      renderHeader: () => (
        <FormattedMessage id="sales_summary.reconcile.help_center.failed_to_be_notified_operations.table.source" />
      ),
      renderCell: ({ row }: { row: PaymentsSourceOfDiscrepanciesViewModel }) => {
        return (
          <Stack alignItems={'flex-start'} justifyContent={'center'} height={'100%'}>
            <Typography>{getOriginLabel(row.origin.paymentProductOrigin, intl)}</Typography>
            {
              row.origin.tableNumber && <Typography variant={'caption'}
                                                    color={theme.palette.text.secondary}>
                <FormattedMessage
                  id={'sales_summary.reconcile.help_center.failed_to_be_notified_operations.table.source.table'} />
                {` ${row.origin.tableNumber}`}
              </Typography>
            }
            {
              row.origin.billCode && <Typography variant={'caption'}
                                                 color={theme.palette.text.secondary}>
                <FormattedMessage
                  id={'sales_summary.reconcile.help_center.failed_to_be_notified_operations.table.source.bill'} />
                {` ${row.origin.billCode}`}
              </Typography>
            }
          </Stack>
        );
      },
    },
    {
      field: 'server', headerName: 'Server', width: 150,
      disableColumnMenu: true,
      renderHeader: () => (
        <FormattedMessage id="sales_summary.reconcile.help_center.failed_to_be_notified_operations.table.server" />
      ),
    },
    {
      field: 'paymentMethod', headerName: 'Source', minWidth: 200, maxWidth: 250,
      disableColumnMenu: true,
      renderHeader: () => (
        <FormattedMessage
          id="sales_summary.reconcile.help_center.failed_to_be_notified_operations.table.payment_method" />
      ),
      renderCell: ({ row }: { row: PaymentsSourceOfDiscrepanciesViewModel }) => {
        return (
          <PaymentMethodCell cardBrand={row.cardInfos?.brand} cardLast4Digits={row.cardInfos?.last4} />
        );
      },
    },
    {
      field: 'sales', headerName: 'Sales', width: 100,
      disableColumnMenu: true,
      renderHeader: () => (
        <FormattedMessage id="sales_summary.reconcile.help_center.failed_to_be_notified_operations.table.sales" />
      ),
    },
    {
      field: 'tips', headerName: 'Tips', width: 100,
      disableColumnMenu: true,
      renderHeader: () => (
        <FormattedMessage id="sales_summary.reconcile.help_center.failed_to_be_notified_operations.table.tips" />
      ),
    },
  ];
};
const TableWithPayments = ({ payments }: {
  payments: PaymentSourceOfDiscrepancies[]
}) => {
  const theme = useTheme();
  const intl = useIntl();
  return <DataGrid
    rows={toViewModel(payments, intl.locale)}
    columns={buildColumns(theme, intl)}
    hideFooter
    slots={{
      noRowsOverlay: NoDataOverlay,
    }}
  />;
};

const Header = ({ onClick, title }: { onClick: () => void, title: string }) => {
  const theme = useTheme();
  return <Box display="flex" flexDirection="row" justifyContent="space-between" padding="20px 24px"
              borderBottom={`1px solid ${theme.palette.secondary.light}`}>
    <Box minWidth="50px" display="flex" alignItems={'center'} sx={{ cursor: 'pointer' }} onClick={onClick}>
      <CrossIcon />
    </Box>
    <Box flexGrow={1} display="flex" justifyContent={'center'}>
      <Typography>
        {title}
      </Typography>
    </Box>
    <Box minWidth="50px"></Box>
  </Box>;
};

const TitleWithSubtitle = ({ nbOfPayments }: { nbOfPayments: number }) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  return <Stack gap={1} paddingTop={6}>
    <Typography variant={'h5'} fontWeight={500}>
      {formatMessage({ id: 'sales_summary.reconcile.help_center.payments_sources_of_discrepancies.title' })}
    </Typography>
    <Stack>
      <Typography variant={'caption'} color={theme.palette.text.secondary}>
        {formatMessage({ id: `sales_summary.reconcile.help_center.payments_sources_of_discrepancies.subtitle${nbOfPayments > 1 ? '_plural' : ''}` }, { nbOfPayments })}
      </Typography>
    </Stack>
  </Stack>;
};

export const PaymentsSourcesOfDiscrepanciesDetailsModal = ({ paymentsSourceOfDiscrepancies, headerTitle, onClose }: {
  paymentsSourceOfDiscrepancies: PaymentSourceOfDiscrepancies[],
  headerTitle: string
  onClose: () => void
}) => {
  const nbOfPayments = (paymentsSourceOfDiscrepancies)!.length ?? 0;
  return <Modal
    open={true}
    aria-labelledby="finalize-checkout-onboarding"
    style={{ overflow: 'scroll', height: '100%' }}
  >
    <Box height={'100%'} overflow={'auto'} sx={{ background: 'white' }} display="flex" flexDirection="column">
      <Header onClick={onClose} title={headerTitle} />
      <Box display="flex" flexDirection="column"
           maxWidth={'830px'}
           marginLeft={'auto'}
           marginRight={'auto'}
           width={'100%'}
           padding="2">
        <Stack gap={4} height={'100%'}>
          <TitleWithSubtitle nbOfPayments={nbOfPayments} />
          <Stack gap={2}>
            <Typography variant={'h6'}>
              <FormattedMessage
                id={`sales_summary.reconcile.help_center.operations${(paymentsSourceOfDiscrepancies)!.length ?? 0 > 1 ? '_plural' : ''}`}
                values={{ value: nbOfPayments }} />
            </Typography>
            <TableWithPayments payments={paymentsSourceOfDiscrepancies} />
          </Stack>
        </Stack>
      </Box>
    </Box>
  </Modal>;
};
