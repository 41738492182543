/* eslint @typescript-eslint/no-redeclare : 'off' */
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Alert, CircularProgress, Stack, Typography } from '@mui/material';
import { colorUsage, spaceUsages, Text } from '@sundayapp/b2b-react-component-library';
import React, { useEffect, useState } from 'react';
import { generatePath, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { TasksModal } from 'src/menu/tasks/components/TasksModal';
import styled from 'styled-components';
import { RouteDefinitions } from 'src/app/RouteDefinitions';
import { BusinessId } from 'src/business/domain/Business';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useGetMenuBusiness } from 'src/menu/common/queries/getMenuBusinessQuery';
import { MenuSummary } from '../../../domain/MenuSummary';
import { useUpdateMenusOrderMutation } from '../../../mutations/menu/useUpdateMenuOrderMutation';
import { useFindMenusBySourceOrBusinessId } from '../../../queries/menu/findMenusBySourceQuery';
import { CreateSundayMenuForm } from './CreateSundayMenuForm';
import { MenuRefreshButton } from './MenuRefreshButton';
import { PreviewMenu } from './PreviewMenu';
import { hasMultiSources, MenuAssetsSources } from 'src/menu/dynamic-menu/domain/MenuAssetsSource';
import MenuItem from './MenuItem';
import { MenuAssetsSourceSelector } from 'src/menu/dynamic-menu/pages/MenusPage/components/MenuAssetsSourceSelector';
import { useMenuAssetsSourcesState } from 'src/menu/dynamic-menu/pages/MenusPage/DigitalMenusContext';
import {
  SwitchEnableOrdering,
} from 'src/menu/dynamic-menu/pages/MenusPage/components/SettingsSwitchers/SwitchEnableOrdering';
import {
  SwitchEnableOpenBill,
} from 'src/menu/dynamic-menu/pages/MenusPage/components/SettingsSwitchers/SwitchEnableOpenBill';
import { DragEndEvent } from '@dnd-kit/core/dist/types/events';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';

export type MenusPageWithBoxesProps = {
  businessId: BusinessId;
  menuAssetsSources: MenuAssetsSources;
};

const MenuItemsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${spaceUsages.mediumLarge};
`;

const TitleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
  width: 100%;
`;

const MessagePanel = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  height: 300px;
`;

export const MenuPageContainer = styled(Stack)`
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  gap: 1em;
`;

const MenuToolContainer = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
`;

function useQueryParams(): URLSearchParams {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function useShowPreviewMenu() {
  const business = useCurrentBusinessOrThrow();
  const configuration = ConfigurationLoader.load();
  return business.patEnrollment && !configuration.businessIdsNotSupportingMenuPreview.includes(business.id);
}

export const MenusPage = ({
  businessId,
  menuAssetsSources,
}: MenusPageWithBoxesProps) => {
  const business = useCurrentBusinessOrThrow();
  const queryParams = useQueryParams();

  const { data: menuBusiness } = useGetMenuBusiness(businessId);

  const [sortedMenus, setSortedMenus] = useState<MenuSummary[]>([]);
  const [showError, setShowError] = useState(false);

  const {
    currentMenuAssetsSource,
    resourcesFilter,
    syncSource,
    updateCurrentMenuAssetsSource,
  } =
    useMenuAssetsSourcesState();

  const { formatMessage } = useIntl();

  const updateMenusOrder = useUpdateMenusOrderMutation(businessId);

  const {
    data: menus,
    isLoading: menuLoading,
  } = useFindMenusBySourceOrBusinessId(businessId, resourcesFilter);

  useEffect(() => {
    setSortedMenus(menus ?? []);
  }, [menus]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEnd(event: DragEndEvent) {
    const {
      active,
      over,
    } = event;

    if (over !== null && active.id !== over.id) {
      const oldIndex = sortedMenus!.findIndex((m) => m.id === active.id);
      const newIndex = sortedMenus!.findIndex((m) => m.id === over.id);

      const newOrderedMenus = arrayMove(sortedMenus, oldIndex, newIndex);
      setSortedMenus(newOrderedMenus);
      updateMenusOrder.mutateAsync(newOrderedMenus.map((m) => m.id));
    }
  }

  function onMenusSynced(menusSynced: MenuSummary[]) {
    setSortedMenus(menusSynced);
  }

  const menuSettingsPath = generatePath('/' + RouteDefinitions.Venue.path + '/' + RouteDefinitions.MenuSettings.path, {
    venueId: business.id,
  });

  const showPreviewMenu = useShowPreviewMenu();

  return (
    <>
      <MenuPageContainer>
        {hasMultiSources(menuAssetsSources) && (
          <TitleContainer>
            <MenuAssetsSourceSelector
              menuAssetsSources={menuAssetsSources}
              value={currentMenuAssetsSource}
              allowAllSourcesOption
              onChange={updateCurrentMenuAssetsSource}
            />
          </TitleContainer>
        )}
        {showError && (
          <Alert severity="error" onClose={() => setShowError(false)}>
            <Typography variant="body1">{formatMessage({ id: 'menus.creation.error' })}</Typography>
          </Alert>
        )}

        {menuBusiness?.orderingAllowed && (
          <SwitchEnableOrdering businessId={businessId} menuBusiness={menuBusiness}
                                menuSettingsPath={menuSettingsPath} />
        )}

        <SwitchEnableOpenBill menuBusiness={menuBusiness} businessId={businessId} />

        <MenuToolContainer>
          <CreateSundayMenuForm
            businessId={businessId}
            setShowError={setShowError}
            menuAssetsSources={menuAssetsSources}
            currentMenuAssetsSource={currentMenuAssetsSource}
          />
          <MenuRefreshButton businessId={businessId} menuAssetsSource={syncSource} onMenusRefreshed={onMenusSynced} />
          {business.patEnrollment && showPreviewMenu && <PreviewMenu enrollmentId={business.patEnrollment.id} />}
        </MenuToolContainer>

        {menuLoading && (
          <MessagePanel>
            <CircularProgress size="large" />
            <Text size="medium" color={colorUsage.disabledText} marginLeft={spaceUsages.medium}>
              <FormattedMessage id="menus.loader.description" />
            </Text>
          </MessagePanel>
        )}

        {!menuLoading && sortedMenus.length > 0 && (
          <MenuItemsContainer>
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
              <SortableContext items={sortedMenus} strategy={verticalListSortingStrategy}>
                {sortedMenus.map((menu) => (
                  <MenuItem businessId={businessId} menu={menu} key={menu.id} />
                ))}
              </SortableContext>
            </DndContext>
          </MenuItemsContainer>
        )}
        {!menuLoading && sortedMenus.length === 0 && (
          <MessagePanel>
            <FormattedMessage id="menus.no-menus-message" />
          </MessagePanel>
        )}
      </MenuPageContainer>
      { queryParams.get('tasks') && <TasksModal businessId={businessId} /> }
    </>
  );
};
