import { Box, Chip } from '@mui/material';
import { PointOfSale } from '@mui/icons-material';
import SundaySvg from 'src/app/component/icons/SundaySvg';
import React from 'react';

type SourceIconProps = {
  isSunday: boolean;
};

const SourceIcon = ({ isSunday }: SourceIconProps) => {
  return isSunday ? (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <SundaySvg size={20} />
      </Box>
  ) : (
    <Chip
      sx={{
        padding: '3px',
        '& .MuiChip-label': {
          padding: '3px',
        },
      }}
      color={'info'}
      size="small"
      icon={<PointOfSale />}
      variant="outlined"
    />
  );
};

export { SourceIcon };
