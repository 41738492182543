import { AxiosStatic } from 'axios';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { Payments } from '../model/Payments';
import { BusinessId } from 'src/business/domain/Business';
import { EndOfServiceConfiguration } from '../model/EndOfServiceConfiguration';
import { Walkouts } from 'src/operations/end-of-service/model/Walkouts';
import { CountryCode } from 'src/domain/CountryCode';
import { CardBrand } from 'src/payments/domain/Payment';
import { Money } from '@sundayapp/web-money';

import { PaymentProductOrigin } from 'src/operations/end-of-service/model/PaymentSourceOfDiscrepancies';

export interface PaymentSourceOfDiscrepanciesDTO {
  createdAt: number;
  paymentId: {
    value: string;
  };
  productOrigin: PaymentProductOrigin;
  sales: Money;
  totalExpectedToBeNotifiedToPos: Money;
  tipsNotifiedToPos: Money;
  digitalFee: Money;
  tableNumber?: string;
  waiterName?: string;
  billCode?: string;
  cardInfos?: {
    countryCode?: CountryCode;
    brand: CardBrand
    last4?: string;
    network?: string;
    isAmex: boolean;
  },
}

interface PaymentsSourceOfDiscrepanciesDTO {
  nonNotifiedPayments: PaymentSourceOfDiscrepanciesDTO[];
  fastPaymentPdq: PaymentSourceOfDiscrepanciesDTO[];
}

export class EndOfServiceClient {

  private readonly accountingApiBaseUrl: string;

  private readonly bookkeepingApiBaseUrl: string;

  constructor(private httpClient: AxiosStatic) {
    const configuration = ConfigurationLoader.load();
    this.accountingApiBaseUrl = configuration.accountingApiBaseUrl;
    this.bookkeepingApiBaseUrl = configuration.bookkeepingApiBaseUrl;
  }

  async summaryOnAPeriod(businessId: BusinessId, startDate: Date, endDate: Date): Promise<Payments> {
    return this.getEOSDataFromProductPayment(businessId, startDate, endDate)
      .then(it => it.data);
  }

  async walkoutsOnAPeriod(businessId: BusinessId, startDate: Date, endDate: Date): Promise<Walkouts> {
    return this.getEOSDataFromWalkout(businessId, startDate, endDate)
      .then(it => it.data);
  }

  async getEndOfServiceConfiguration(businessId: BusinessId): Promise<EndOfServiceConfiguration> {
    return this.httpClient.get<EndOfServiceConfiguration>(`${this.bookkeepingApiBaseUrl}/eos/businesses/${businessId}/configuration`, {
      headers: {
        contentType: 'application/json',
      },
    })
      .then(it => it.data)
      .catch(() => {
        return ({
          bourdoncleModeEnabled: false,
          displayPrintMode: false,
          displayRevenueCenters: false,
        } as EndOfServiceConfiguration);
      },
      );
  }

  async reconcilePOSOperationsWithSundayPayments(businessId: BusinessId, startDate: Date, endDate: Date): Promise<String | null> {

    return this.httpClient.get<String>(`${this.bookkeepingApiBaseUrl}/reconciliation/${businessId}/export`, {
      headers: {
        contentType: 'text/csv',
      },
      params: {
        startDate: startDate,
        endDate: endDate,
      },
    })
      .then(it => it.data)
      .catch(() => {
        return null;
      },
      );
  }

  private getEOSDataFromProductPayment(businessId: BusinessId, startDate: Date, endDate: Date) {
    return this.httpClient.get<Payments>(`${this.accountingApiBaseUrl}/eos/businesses/${businessId}/payments`, {
      headers: {
        contentType: 'application/json',
      },
      params: {
        from: startDate,
        to: endDate,
      },
    });
  }

  private getEOSDataFromWalkout(businessId: BusinessId, startDate: Date, endDate: Date) {
    return this.httpClient.get<Walkouts>(`${this.accountingApiBaseUrl}/eos/businesses/${businessId}/manual-walkouts`, {
      headers: {
        contentType: 'application/json',
      },
      params: {
        from: startDate,
        to: endDate,
      },
    });
  }

  async getPaymentsSourceOfDiscrepancies(businessId: BusinessId, startDate: Date, endDate: Date): Promise<PaymentsSourceOfDiscrepanciesDTO | null> {
    return this.httpClient.get<PaymentsSourceOfDiscrepanciesDTO>(`${this.bookkeepingApiBaseUrl}/reconciliation/${businessId}/payments-source-of-discrepancies`, {
      headers: {
        contentType: 'application/json',
      },
      params: {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      },
    })
      .then(it => it.data)
      .catch(() => {
        return null;
      },
      );

  }
}
