import { Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import {
  UploadIncorporationDocument,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/SupportingDocuments/UploadIncorporationDocument';
import React from 'react';
import {
  ArticlesOfAssociationDocument,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/SupportingDocuments/ArticlesOfAssociationDocument';
import {
  ShareholderStructureDocument,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/SupportingDocuments/ShareholderStructureDocument';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useFormContext } from 'react-hook-form';
import {
  ProofOfPrincipalAddressDocument,
} from 'src/payments/components/Checkout/CheckoutOnboardingFR/SupportingDocuments/ProofOfPrincipalAddressDocument';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { EinLetterDocument } from 'src/payments/components/Checkout/CheckoutOnboardingFR/SupportingDocuments/EinLetterDocument';

export const SupportingDocuments = () => {
  const business = useCurrentBusinessOrThrow();
  const { watch } = useFormContext<CheckoutOnboardingInformationDraft>();
  const isPrimaryAddressTheSameAsRegistered = watch('isPrimaryAddressTheSameAsRegistered');

  return <Stack direction="column" spacing={3}>
    <Typography variant={'h5'}>
      5 · <FormattedMessage id="settings.payments.checkout.onboarding.supportingDocuments"
                            defaultMessage={'Supporting documents'} />
    </Typography>
    {business.address.countryCode === 'US' && <EinLetterDocument />}
    {business.address.countryCode != 'GB' && <UploadIncorporationDocument />}
    <ArticlesOfAssociationDocument />
    <ShareholderStructureDocument />
    {!isPrimaryAddressTheSameAsRegistered && <ProofOfPrincipalAddressDocument />}
  </Stack>;
};
