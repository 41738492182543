import { useFormContext } from 'react-hook-form';
import React from 'react';
import { UploadDocument } from 'src/payments/components/Checkout/UploadDocuments/UploadDocument';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const EinLetterDocument = () => {
  const {
    formState: { errors },
    register,
    watch,
    setValue,
  } = useFormContext<CheckoutOnboardingInformationDraft>();
  const error = errors.einDocument;
  const uploadedDocument = watch('einDocument');
  const intl = useIntl();

  const onFileUploaded = (documentId: string) => {
    setValue('einDocument', documentId);
  };
  const onDelete = () => {
    setValue('einDocument', '');
  };

  return (
    <UploadDocument
      onRegistered={register('einDocument', { required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.einDocument.error' }) })}
      onFileUploaded={onFileUploaded}
      onDelete={onDelete}
      documentId={uploadedDocument}
      error={error}
      documentTitle={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.einDocument' })}
      purpose={'ein_letter'}
      helperText={''}
    />
  );
};
