import { generatePath, Navigate, useSearchParams } from 'react-router-dom';
import React from 'react';
import {
  venuePath,
  venuePaymentsPath,
  venueStaffPerformancePath,
  venueSundayPlusInsightsPath,
  venueSundayPlusReviewsPath,
} from '../pathHelpers';
import { marketingTargets, redirectionSearchParamName } from '../marketinEmailRedirections';
import { RouteDefinitions } from '../../RouteDefinitions';
import { useCurrentUser } from 'src/auth/component/UserProvider';
import { BusinessId } from 'src/business/domain/Business';
import { hasAccessToMultiLocations } from 'src/multi-locations/domain/User';

export const RedirectToFirstUserVenue = () => {
  const currentUser = useCurrentUser();
  const [searchParams] = useSearchParams();

  if (!currentUser || !currentUser.authenticated) {
    return <Navigate to={generatePath(`${RouteDefinitions.SignIn.path}`)} />;
  }

  if (currentUser.claims.length === 0) {
    return <Navigate to={generatePath(`${RouteDefinitions.NoVenue.path}`)} />;
  }

  if (hasAccessToMultiLocations(currentUser)) {
    return <Navigate to={generatePath(`${RouteDefinitions.AllLocations.path}`)} />;
  }

  const firstUserVenueId = currentUser.claims[0].businessId as BusinessId;

  const redirectionTarget = searchParams.get(redirectionSearchParamName);

  // TODO: see how to move this logic directly in loader
  // Not easy because user venues are not part of request as JWT so hard to provide to loader
  // Refactor when stateless authent system with claims
  switch (redirectionTarget) {
    case marketingTargets.PAYMENTS:
      return <Navigate to={venuePaymentsPath(firstUserVenueId)} />;
    case marketingTargets.STAFF_PERFORMANCE:
      return <Navigate to={venueStaffPerformancePath(firstUserVenueId)} />;
    case marketingTargets.REVIEWS:
      return <Navigate to={venueSundayPlusReviewsPath(firstUserVenueId)} />;
    case marketingTargets.INSIGHTS:
      return <Navigate to={venueSundayPlusInsightsPath(firstUserVenueId)} />;
    default:
      return <Navigate to={venuePath(firstUserVenueId)} />;
  }
};
