import { Box, Button, Chip, CircularProgress, styled, Tooltip } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import {
  PaymentTerminal,
  PaymentTerminalConfigurationSummaryDto,
  PaymentTerminalWithStatus,
} from '../domain/PaymentTerminalRepository';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { HttpPaymentTerminalRepository } from '../infrastructure/HttpPaymentTerminalRepository';
import { IntlShape } from 'react-intl/src/types';
import { PaymentTerminalMenu } from './PaymentTerminalMenu';
import PlusSvg from '../../app/component/icons/PlusSvg';
import AddPaymentTerminalModal from './AddPaymentTerminalModal';
import { ErrorOutline } from '@mui/icons-material';
import { ProvisioningQrCodeModal } from './ProvisioningQrCodeModal';

const OnlineDot = styled('span')`
  color: green;
`;

const OfflineDot = styled('span')`
  color: red;
`;

const Header = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 8px;
`;

const buildColumns = (intl: IntlShape,
  enrollmentId: EnrollmentId,
  paymentTerminalConfigurations: PaymentTerminalConfigurationSummaryDto[],
  qrCodeOnclick: (url: string, id: string) => void,
  callback: () => void): GridColDef[] => [
  {
    field: 'name',
    disableColumnMenu: true,
    flex: 1,
    headerName: 'name',
    renderHeader: () => <FormattedMessage id="payment.terminal.manage.list.header.name" />,
  },
  {
    field: 'serial_number',
    flex: 1,
    disableColumnMenu: true,
    renderHeader: () => <FormattedMessage id="payment.terminal.manage.list.header.serial" />,
    renderCell: ({ row: paymentTerminal }: GridCellParams<PaymentTerminal>) =>
      <span>{paymentTerminal.serialNumber}</span>,
  },
  {
    field: 'apkVersion',
    disableColumnMenu: true,
    flex: 1,
    renderHeader: () => <FormattedMessage id="payment.terminal.manage.list.header.apkVersion" />,
    renderCell: ({ row: paymentTerminal }: GridCellParams<PaymentTerminalWithStatus>) =>
      <span>{paymentTerminal.apkVersion !== undefined ? paymentTerminal.apkVersion : <CircularProgress />}</span>,
  },
  {
    field: 'configuration',
    disableColumnMenu: true,
    flex: 1,
    renderHeader: () => <FormattedMessage id="payment.terminal.manage.list.header.configuration" />,
    renderCell: ({ row: paymentTerminal }: GridCellParams<PaymentTerminalWithStatus>) =>
      <span>{paymentTerminalConfigurations.find((configuration) => configuration.id === paymentTerminal.configurationId)?.name}</span>,
  },
  {
    field: 'provisioning',
    disableColumnMenu: true,
    flex: 1,
    sortable: false,
    renderHeader: () => <FormattedMessage id="payment.terminal.manage.list.header.provisioning" />,
    renderCell: ({ row: paymentTerminal }: GridCellParams<PaymentTerminalWithStatus>) =>
      (paymentTerminal.serialNumber ? <FormattedMessage id="payment.terminal.manage.list.associated" /> :
        <QrCodeIcon onClick={() => qrCodeOnclick(paymentTerminal.provisioningUrl, paymentTerminal.id)} />),
  },
  {
    field: 'online',
    disableColumnMenu: true,
    flex: 1,
    renderHeader: () => <FormattedMessage id="payment.terminal.manage.list.header.onlineStatus" />,
    renderCell: ({ row: paymentTerminal }: GridCellParams<PaymentTerminalWithStatus>) => {
      if (paymentTerminal.onlineStatus === undefined) {
        return <CircularProgress />;
      }
      if (paymentTerminal.onlineStatus === 'ONLINE') {
        return (
          <Tooltip title={intl.formatMessage({ id: 'payment.terminal.manage.list.tooltip.onlineStatus' })}>
            <OnlineDot>{`${intl.formatMessage({ id: 'payment.terminal.manage.list.tooltip.online' })} ●`}</OnlineDot>
          </Tooltip>
        );
      }
      if (paymentTerminal.onlineStatus === 'OFFLINE') {
        return (
          <Tooltip title={intl.formatMessage({ id: 'payment.terminal.manage.list.tooltip.offlineStatus' })}>
            <OfflineDot>{`${intl.formatMessage({ id: 'payment.terminal.manage.list.tooltip.offline' })} ●`}</OfflineDot>
          </Tooltip>
        );
      }
      return (
        <Tooltip title={intl.formatMessage({ id: 'payment.terminal.manage.list.tooltip.unknownStatus' })}>
          <span>{`${intl.formatMessage({ id: 'payment.terminal.manage.list.tooltip.unknown' })} ●`}</span>
        </Tooltip>
      );
    },
  },
  {
    field: 'hasLatestConfiguration',
    disableColumnMenu: true,
    flex: 1,
    sortable: false,
    renderHeader: () => <FormattedMessage id="payment.terminal.manage.list.header.configuration-up-to-date" />,
    renderCell: ({ row: paymentTerminal }: GridCellParams<PaymentTerminalWithStatus>) =>
      (paymentTerminal.hasLatestConfiguration ?
        <Chip color="success"
              label={intl.formatMessage({ id: 'payment.terminal.manage.list.configuration-up-to-date' })} /> :
        <Chip
          icon={<ErrorOutline />}
          color="warning"
          label={intl.formatMessage({ id: 'payment.terminal.manage.list.configuration-out-of-date' })} />),
  },
  {
    field: 'actions',
    disableColumnMenu: true,
    flex: 1,
    sortable: false,
    renderHeader: () => <FormattedMessage id="payment.terminal.manage.list.header.actions" />,
    renderCell: ({ row: paymentTerminal }: GridCellParams<PaymentTerminalWithStatus>) => {
      return <PaymentTerminalMenu enrollmentId={enrollmentId} paymentTerminal={paymentTerminal} callback={callback} />;
    },
  },
];

interface Props {
  enrollmentId: EnrollmentId
}

function ListPaymentTerminals({ enrollmentId }: Props) {
  const intl = useIntl();
  const configuration = ConfigurationLoader.load();
  const repository = new HttpPaymentTerminalRepository(configuration.paymentTerminalBaseUrl);
  const [paymentTerminals, setPaymentTerminals] = useState<PaymentTerminalWithStatus[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [paymentTerminalConfigurations, setPaymentTerminalConfigurations] = useState<PaymentTerminalConfigurationSummaryDto[]>([]);
  const [provisioningUrl, setProvisioningUrl] = useState<string>();
  const [paymentTerminalId, setPaymentTerminalId] = useState<string>();

  const loadData = () => {
    repository.listPaymentTerminals(enrollmentId, false).then((terminals) => {
      setIsLoading(false);
      setPaymentTerminals(terminals);

      repository.onlineStatuses(enrollmentId).then((statuses) => {

        const terminalWithStatuses: PaymentTerminalWithStatus[] = [];
        terminals.forEach((terminal) => {
          const withStatus: PaymentTerminalWithStatus = {
            id: terminal.id,
            name: terminal.name,
            serialNumber: terminal.serialNumber,
            provisioningUrl: terminal.provisioningUrl,
            hasLatestConfiguration: terminal.hasLatestConfiguration,
            apkVersion: '',
            onlineStatus: '',
            configurationId: terminal.configurationId,
          };

          const status = statuses.find((terminalWithDetails) => terminalWithDetails.id === terminal.id);
          if (status) {
            withStatus.apkVersion = status.apkVersion || '-';
            withStatus.onlineStatus = status.onlineStatus || '';
          }
          terminalWithStatuses.push(withStatus);
        });
        setPaymentTerminals(terminalWithStatuses);
      });

      repository.configurations(enrollmentId).then((configurations) => {
        setPaymentTerminalConfigurations(configurations);
      });

    });
  };

  useEffect(() => {
    loadData();
  }, [enrollmentId]);

  const handleClose = () => {
    setIsOpen(false);
    loadData();
  };

  const qrCodeOnclick = (url: string | undefined, id: string) => {
    setProvisioningUrl(url);
    setPaymentTerminalId(id);
  };

  if (isLoading || !paymentTerminals) return <CircularProgress />;
  return (
    <>
      <Header>
        <Button
          variant="contained"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <Box display="flex" alignItems="center" gap="8px">
            <PlusSvg fontSize="large" color="white" />
            <FormattedMessage id="payment.terminal.manage.new.tpe" />
          </Box>
        </Button>
      </Header>
      <DataGrid
        autoHeight
        rows={paymentTerminals}
        columns={buildColumns(intl, enrollmentId, paymentTerminalConfigurations, qrCodeOnclick, loadData)}
        disableRowSelectionOnClick
        hideFooter
      />
      <AddPaymentTerminalModal enrollmentId={enrollmentId} isOpen={isOpen} onClose={() => handleClose()} />
      <ProvisioningQrCodeModal onClose={() => setProvisioningUrl(undefined)} provisioningUrl={provisioningUrl}  enrollmentId = {enrollmentId} paymentTerminalId={paymentTerminalId} />
    </>
  );
}

export default ListPaymentTerminals;
