import React, { useMemo } from 'react';
import styled from 'styled-components';
import { getSpacing } from '@sundayapp/b2b-react-component-library';
import { useParams } from 'react-router-dom';
import {
  AreaCreationSettings as AreaCreation,
} from '../../../ordering/area/pages/AreaCreationSettings/AreaCreationSettings';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { CircularProgress } from '@mui/material';

const OrderingSettingPanel = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${getSpacing(2)};
  margin-bottom: ${getSpacing(2)};
  width: 100%;
`;

export const AreaCreationSettings = () => {
  const business = useCurrentBusinessOrThrow();
  const { boxId } = useParams();

  const areaCreationComponent = useMemo(() => {
    if (!business.oapEnrollment!.id || !boxId) {
      return <CircularProgress size="medium" />;
    }

    return <AreaCreation businessId={business.id} enrollmentId={business.oapEnrollment!.id} boxId={boxId} />;
  }, [business.oapEnrollment!.id]);

  return (
    <OrderingSettingPanel>{areaCreationComponent}</OrderingSettingPanel>
  );
};
