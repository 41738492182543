import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import {
  getStartAndEndDateForChosenDateAndShiftOptions,
} from 'src/operations/end-of-service/hooks/getStartAndEndDateForChosenDateAndShiftOptions';
import { useReconcile } from 'src/operations/end-of-service/queries/useReconcile';
import { ampli } from 'src/ampli';
import { Business } from 'src/business/domain/Business';
import { RestaurantShiftTimesOptions } from 'src/operations/restaurant-shifts/hooks/useRestaurantShiftTimesOptions';
import { RestaurantShiftTimesOption } from 'src/operations/restaurant-shifts/models/RestaurantShiftTimesOption';
import Link from '@mui/material/Link';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {
  Cta,
  PaymentsSourceOfDiscrepancies,
} from 'src/operations/end-of-service/components/reconciliationHelpCenter/PaymentsSourceOfDiscrepancies';

const Container = styled(Stack)`
  border-radius: 16px;
  padding: 16px;
  background-color: #ebedfb;
`;

const Title = () => (
  <Typography variant={'h6'} color={'#2D3482'} fontWeight={500}>
    <FormattedMessage id="sales_summary.reconcile.help_center.title" />
  </Typography>
);

export const ReconciliationReport = ({
  startDate,
  endDate,
  business,
}: {
  business: Business;
  startDate: Date;
  endDate: Date;
}) => {
  const { formatMessage } = useIntl();

  const [isFailureDialogOpen, setIsFailureDialogOpen] = React.useState(false);
  const onNoResultOrFailure = () => setIsFailureDialogOpen(true);
  const { mutateAsync, isPending } = useReconcile({
    businessId: business.id,
    startDate: startDate,
    endDate: endDate,
  });
  const onReconcile = async () => {
    try {
      ampli.reconciliationReportClicked({
        businessId: business.id,
        reportingDate: startDate.toLocaleString(),
      });
      await mutateAsync();
    } catch (e) {
      onNoResultOrFailure();
    }
  };
  const onCloseFailureDialog = () => setIsFailureDialogOpen(false);
  return (
    <>
      <Stack gap={1}>
        <Typography variant="caption">
          <FormattedMessage id={'sales_summary.reconcile.help_center.line_by_line_comparison.title'} />
          <Link
            color="text.primary"
            target={'_blank'}
            onClick={() => {
              ampli.reconciliationIntercomHelpClicked({
                businessId: business.id,
                reportingDate: startDate.toLocaleString(),
              });
            }}
            href={formatMessage({ id: 'sales_summary.reconcile.title.intercom.link' })}
          >
            <FormattedMessage id={'sales_summary.reconcile.title.intercom.label'} />
          </Link>
        </Typography>
        <Cta
          onClick={onReconcile}
          label={formatMessage({ id: 'sales_summary.reconcile.help_center.line_by_line_comparison' })}
          icon={<AssessmentOutlinedIcon fontSize={'large'} />}
          rightSide={isPending ? <CircularProgress /> : <FileDownloadOutlinedIcon fontSize={'large'} />}
        />
      </Stack>
      <Dialog open={isFailureDialogOpen} onClose={onCloseFailureDialog}>
        <DialogTitle>
          <FormattedMessage id={'sales_summary.reconcile.error.title'} />
        </DialogTitle>
        <DialogContent>
          <Typography>
            <FormattedMessage id={'sales_summary.reconcile.error'} />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseFailureDialog}>
            <FormattedMessage id={'sales_summary.reconcile.error.close'} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const ReconciliationHelpCenter = ({
  business,
  reportingDate,
  options,
  restaurantShiftTimesOption,
}: {
  business: Business;
  reportingDate: Date;
  options: RestaurantShiftTimesOptions;
  restaurantShiftTimesOption: RestaurantShiftTimesOption;
}) => {
  const { startAtLocalBusiness, endAtLocalBusiness } = getStartAndEndDateForChosenDateAndShiftOptions(
    options,
    restaurantShiftTimesOption,
    reportingDate,
    business,
  );

  return (
    <Container spacing={2}>
      <Title />
      <PaymentsSourceOfDiscrepancies
        business={business}
        startDate={startAtLocalBusiness}
        endDate={endAtLocalBusiness}
      />
      <ReconciliationReport business={business} startDate={startAtLocalBusiness} endDate={endAtLocalBusiness} />
    </Container>
  );
};
