import { Alert, Box, Button, Card, Chip, CircularProgress, Typography } from '@mui/material';
import { extractTimeFrameFilterFromURLParams, TimeFrameFilter } from 'src/payments/components/TimeFrameFilter';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { translation, Translation } from 'src/accounting/common/translation';
// eslint-disable-next-line no-restricted-imports
import moment, { Moment } from 'moment-timezone';
import { DateRange } from 'src/accounting/revenues/domain/RevenuesDatePresets';
import React, { useMemo } from 'react';
import { ValuesOf } from 'src/utils/typescript/valuesOf';
import { useMerchantClawbacks } from 'src/accounting/clawbacks/infrastructure/useMerchantClawbacks';
import { EmptyTableCellValue } from 'src/payments/components/EmptyTableCellValue';
import { renderMoney } from '@sundayapp/web-money';
import { FormattedMessage, IntlShape, useIntl } from 'src/app/i18n/TypedIntl';
import { DataGrid, GridCellParams, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { Pagination } from 'src/menu/dynamic-menu/pages/ProductsPage/hooks/useSearchProductsFilters';
import { useSearchParams } from 'react-router-dom';
import { Clawback, clawbackStatuses } from 'src/accounting/clawbacks/domain/MerchantClawbacks';
import { TableEmptyState } from 'src/components/table/TableEmptyState';
import { isClawbackReportFile } from 'src/accounting/clawbacks/infrastructure/ClawbackRepository';
import DownloadIcon from '@mui/icons-material/Download';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { useClawbackReportDownloader } from 'src/accounting/clawbacks/infrastructure/useDownloadClawbacksReport';


export const clawbacksDatePresetTypes = {
  LAST_7_DAYS_FILTER: 'LAST_7_DAYS_FILTER',
  LAST_WEEK_FILTER: 'LAST_WEEK_FILTER',
  MONTH_TO_DATE_FILTER: 'MONTH_TO_DATE_FILTER',
  LAST_MONTH_FILTER: 'LAST_MONTH_FILTER',
  CURRENT_YEAR_FILTER: 'CURRENT_YEAR_FILTER',
  PREVIOUS_YEAR_FILTER: 'PREVIOUS_YEAR_FILTER',
  CUSTOM: 'CUSTOM',
} as const;

type ClawbacksDatePreset = ValuesOf<typeof clawbacksDatePresetTypes>;

function clawbacksDatePresets(timezone: string): Record<ClawbacksDatePreset, {
  range: DateRange,
  translation: Translation
}> {
  const now = (): Moment => moment()
    .tz(timezone);
  const shiftStartOf = (aMoment: Moment) => aMoment.hour(4);
  const shiftEndOf = (aMoment: Moment) => aMoment.hour(3)
    .minute(59)
    .second(59);

  return ({
    [clawbacksDatePresetTypes.LAST_7_DAYS_FILTER]: {
      range: {
        startDate: shiftStartOf(now()
          .subtract(7, 'd')
          .startOf('day')),
        endDate: shiftEndOf(now()),
      },
      translation: translation('accounting.payout_tab.last_7_days_filter'),
    },
    [clawbacksDatePresetTypes.LAST_WEEK_FILTER]: {
      range: {
        startDate: shiftStartOf(now()
          .subtract(1, 'week')
          .startOf('isoWeek')
          .startOf('day')),
        endDate: shiftEndOf(now()
          .subtract(1, 'week')
          .endOf('isoWeek')
          .endOf('day')
          .add(1, 'days')),
      },
      translation: translation('accounting.payout_tab.last_week_filter'),
    },
    [clawbacksDatePresetTypes.MONTH_TO_DATE_FILTER]: {
      range: {
        startDate: shiftStartOf(now()
          .startOf('month')
          .startOf('day')),
        endDate: shiftEndOf(now()
          .add(1, 'days')),
      },
      translation: translation('accounting.payout_tab.month_to_date_filter'),
    },
    [clawbacksDatePresetTypes.LAST_MONTH_FILTER]: {
      range: {
        startDate: shiftStartOf(now()
          .subtract(1, 'month')
          .startOf('month')
          .startOf('day')),
        endDate: shiftEndOf(now()
          .subtract(1, 'month')
          .endOf('month')
          .endOf('day')
          .add(1, 'days')),
      },
      translation: translation('accounting.payout_tab.last_month_filter'),
    },
    [clawbacksDatePresetTypes.CURRENT_YEAR_FILTER]: {
      range: {
        startDate: shiftStartOf(now()
          .startOf('year')
          .startOf('day')),
        endDate: shiftEndOf(now()
          .endOf('day')),
      },
      translation: translation('accounting.payout_tab.current_year_filter', {
        currentYear: now()
          .year(),
      }),
    },
    [clawbacksDatePresetTypes.PREVIOUS_YEAR_FILTER]: {
      range: {
        startDate: shiftStartOf(now()
          .subtract(1, 'year')
          .startOf('year')
          .startOf('day')),
        endDate: shiftEndOf(now()
          .subtract(1, 'year')
          .endOf('year')
          .add(1, 'days')),
      },
      translation: translation('accounting.payout_tab.previous_year_filter', {
        previousYear: now()
          .subtract(1, 'year')
          .year(),
      }),
    },
    [clawbacksDatePresetTypes.CUSTOM]: {
      range: {
        startDate: shiftStartOf(now()),
        endDate: shiftEndOf(now()),
      },
      translation: translation('accounting.revenue_tab.custom_filter'),
    },
  });
}


const buildColumns = (intl: IntlShape): GridColDef<Clawback>[] => ([
  {
    field: 'createdAt',
    flex: 1,
    sortable: false,
    headerName: intl.formatMessage({ id: 'accounting.clawbacks.table.header.created_at' }),
    renderCell: ({ row }: GridCellParams<Clawback>) => row.createdAt.toLocaleDateString(intl.locale, {
      weekday: 'long',
      month: 'short',
      day: 'numeric',
    }),
  },
  {
    field: 'amount',
    flex: 1,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    headerName: intl.formatMessage({ id: 'accounting.clawbacks.table.header.amount' }),
    renderCell: ({ row }: GridCellParams<Clawback>) => renderMoney(row.amount, intl.locale),
  }, {
    field: 'status',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    headerName: intl.formatMessage({ id: 'accounting.clawbacks.table.header.status' }),
    renderCell: ({ row }: GridCellParams<Clawback>) => {
      switch (row.status) {
        case 'PAID':
          return <Chip variant="outlined"
                       color="success"
                       label={intl.formatMessage({ id: `accounting.clawbacks.table.status.${row.status}` })} />;
        case 'PAYMENT_INITIATED':
        case 'READY_TO_BE_PAID':
          return <Chip variant="outlined"
                       color="warning"
                       label={intl.formatMessage({ id: `accounting.clawbacks.table.status.${row.status}` })} />;
      }
    },
  },
]);


function paginationFromUrlParams(searchParams: URLSearchParams) {
  const pageStr = searchParams.get('page');
  const sizeStr = searchParams.get('size');

  const page = pageStr ? +pageStr : 1;
  const size = sizeStr ? +sizeStr : 60;

  return {
    page: page < 0 ? 0 : page,
    pageSize: size < 5 ? 5 : size,
  };
}

export const ClawbacksPage = () => {
  const intl = useIntl();
  const business = useCurrentBusinessOrThrow();
  const datePresets = clawbacksDatePresets(business.timezone);
  const period = useMemo(
    () => extractTimeFrameFilterFromURLParams<typeof clawbacksDatePresetTypes>(location.search, datePresets, clawbacksDatePresetTypes.LAST_7_DAYS_FILTER),
    [location.search],
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const snackBar = useSnackbar();
  const pagination: GridPaginationModel = paginationFromUrlParams(searchParams);
  const onPaginationChanged = (newPagination: Pagination) => {
    setSearchParams((prev) => {
      prev.set('page', newPagination.page.toString());
      prev.set('size', newPagination.pageSize.toString());
      return prev;
    });
  };

  const { data } = useMerchantClawbacks(business.id, {
    from: period.dateRange.startDate.clone().utc().format(),
    to: period.dateRange.endDate.clone().utc().format(),
    page: pagination.page,
    size: pagination.pageSize,
  });
  const reportDownloader = useClawbackReportDownloader(business.id);

  const somePaidClawbacks = data?.clawbacks.items.some((clawback) => clawback.status === clawbackStatuses.PAID) || false;
  const isFrenchBusiness = business.address.countryCode === 'FR';

  const downloadReport = async () => {
    const result = await reportDownloader(period.dateRange);
    if (!isClawbackReportFile(result)) {
      snackBar.addNotification({
        variant: 'error',
        text: intl.formatMessage({ id: 'accounting.clawbacks.page.report.download.error' }),
      });
      return;
    }
    const { fileName, data: reportData } = result;
    const blob = new Blob([reportData], { type: 'application/pdf' });
    const shadowElement = document.createElement('a');
    shadowElement.download = fileName;
    shadowElement.href = URL.createObjectURL(blob);
    shadowElement.click();
  };

  return <Box display="flex" flexDirection="column" gap={4} maxWidth="782px">
    <Typography variant="subtitle1" color="text.secondary">
      <FormattedMessage id="accounting.clawbacks.page.subtitle" />
    </Typography>
    {isFrenchBusiness && <Alert severity="info">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography>
          <FormattedMessage id="accounting.clawbacks.page.nepting_info.message" />
        </Typography>
      </Box>
    </Alert>}

    <Box display="flex" flexDirection="column" gap={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <TimeFrameFilter datePresets={datePresets} period={period} timezone={business.timezone} />
        {somePaidClawbacks && <Button onClick={downloadReport} size="small" variant="contained" color="primary">
          <DownloadIcon />
          &nbsp;
          <FormattedMessage id="accounting.clawbacks.page.report.download.button" />
        </Button>}
      </Box>

      <Box>
        {!data
          ? <CircularProgress />
          : data.clawbacks.totalCount === 0
            ? <TableEmptyState title={{ id: 'accounting.clawbacks.table.empty_state.title', values: {} }} />
            : <Box display="flex" flexDirection="column" gap={3}>
              <Card sx={{ padding: 3, gap: 1 }}>
                <Typography variant="body1" color="text.secondary">
                  <FormattedMessage id="accounting.clawbacks.page.card.total_charged" />
                </Typography>
                <Typography variant="h5">
                  {data.totalCharged ? renderMoney(data.totalCharged, intl.locale) : <EmptyTableCellValue />}
                </Typography>
              </Card>

              <DataGrid
                rows={data.clawbacks.items}
                columns={buildColumns(intl)}
                rowCount={data.clawbacks.totalCount || 0}
                pageSizeOptions={[]}
                pagination
                paginationModel={pagination}
                paginationMode="server"
                onPaginationModelChange={onPaginationChanged}
                disableRowSelectionOnClick
              />
            </Box>}
      </Box>
    </Box>

  </Box>
  ;
};
