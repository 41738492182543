import { Staff } from 'src/tips/dispatch/model/Staff';
import { Divider, Stack, Typography, useTheme } from '@mui/material';
import { WarningAmberOutlined } from '@mui/icons-material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { nonEligibleStaff, staffName } from 'src/tips/dispatch/model/StaffAllocation';
import React from 'react';

export const NonEligibleStaff = ({ staffs }: { staffs: Staff[] }) => {
  const theme = useTheme();

  const nonEligible = nonEligibleStaff(staffs);

  if (nonEligible.length === 0) return null;
  return (
    <Stack gap={2}>
      <Divider />
      <Stack gap={1}>
        <Stack direction="row" alignItems="center" gap={1}>
          <WarningAmberOutlined color="warning" />
          <Typography variant={'h6'}>
            <FormattedMessage id={'tips.sunday_pooling.dispatch.non_eligible'} />
          </Typography>
        </Stack>
        <Typography variant={'body2'} color={theme.palette.text.secondary}>
          <FormattedMessage id={'tips.sunday_pooling.dispatch.non_eligible.details'} />
        </Typography>
      </Stack>
      <Stack direction="column" gap={1}>
        {nonEligible.map((s) => (
          <Typography key={s.id}>{staffName(s)}</Typography>
        ))}
      </Stack>
    </Stack>
  );
};
