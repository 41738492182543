import React, { FC, JSX, useState } from 'react';
import { PendingTipsDispatch } from 'src/tips/dispatch/model/PendingTipsDispatch';
import { StaffAllocation } from 'src/tips/dispatch/model/StaffAllocation';
import { Staff } from 'src/tips/dispatch/model/Staff';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Button, Stack, Typography } from '@mui/material';
import { DispatchPrerequisites } from 'src/tips/dispatch/components/DispatchPrerequisites';
import { palette } from 'src/organization-report/design/palette';
import { WhiteButton } from 'src/tips/dispatch/components/WhiteButton';
import { NonEligibleStaff } from 'src/tips/dispatch/components/NonEligibleStaff';
import { absMoney, differenceMoney, money, Money, MoneyView, sumMoneys } from '@sundayapp/web-money';
import { CheckCircle, ChevronLeftOutlined, Warning } from '@mui/icons-material';
import { StaffDispatchByHours } from 'src/tips/dispatch/components/StaffDispatchByHours';
import { DispatchStrategy } from 'src/tips/dispatch/model/DispatchStrategy';
import { StaffDispatchByAmounts } from 'src/tips/dispatch/components/StaffDispatchByAmounts';

type DispatchAllocationResult = {
  difference: Money;
  status: 'OVER' | 'UNDER' | 'READY';
};

function resolveDispatchAllocationResult(
  staffAllocations: StaffAllocation[],
  dispatchAmount: Money,
): DispatchAllocationResult {
  const assigned = staffAllocations.reduce(
    (acc, { allocation }) => sumMoneys(acc, allocation),
    money(0, dispatchAmount.currency),
  );
  const difference = differenceMoney(dispatchAmount, assigned);
  return {
    difference: absMoney(difference),
    status:
      assigned.amount > dispatchAmount.amount ? 'OVER' : assigned.amount < dispatchAmount.amount ? 'UNDER' : 'READY',
  };
}

const buildBannerContent = (
  result: DispatchAllocationResult,
  dispatchAmount: Money,
): {
  message: JSX.Element;
  subtitle: JSX.Element;
  icon: JSX.Element;
} =>
  ({
    OVER: {
      message: (
        <FormattedMessage
          id={'tips.sunday_pooling.dispatch.status.over'}
          values={{ amount: <MoneyView value={result.difference} /> }}
        />
      ),
      subtitle: <FormattedMessage id={'tips.sunday_pooling.dispatch.error.action'} />,
      icon: <Warning sx={{ color: 'white' }} />,
    },
    UNDER: {
      message: (
        <FormattedMessage
          id={'tips.sunday_pooling.dispatch.status.under'}
          values={{ amount: <MoneyView value={result.difference} /> }}
        />
      ),
      subtitle: <></>,
      icon: <Warning sx={{ color: 'white' }} />,
    },
    READY: {
      message: (
        <FormattedMessage
          id={'tips.sunday_pooling.dispatch.status.ready'}
          values={{ amount: <MoneyView value={dispatchAmount} /> }}
        />
      ),

      subtitle: <></>,
      icon: <CheckCircle sx={{ color: 'white' }} />,
    },
  })[result.status];

export const DispatchTips: FC<{
  onClose: () => void;
  tipsToDispatch: PendingTipsDispatch;
  nextStep: (allocations: StaffAllocation[]) => void;
  staffs: Staff[];
  strategy: DispatchStrategy;
}> = ({ nextStep, onClose, staffs, tipsToDispatch, strategy }) => {
  const [staffAllocations, setStaffAllocations] = useState<StaffAllocation[]>([]);
  const onClick = () => nextStep(staffAllocations);

  const dispatchAllocationResult = resolveDispatchAllocationResult(staffAllocations, tipsToDispatch.amount);
  const canContinue = dispatchAllocationResult.status === 'READY';
  const bannerContent = buildBannerContent(dispatchAllocationResult, tipsToDispatch.amount);

  return (
    <Stack sx={{ background: 'white', height: '100%', overflow: 'auto', paddingBottom: '1rem' }} flexDirection="column">
      <Stack direction="row" justifyContent="space-between" alignItems={'center'} padding="1rem 1.5rem 1rem 1.5rem">
        <Stack sx={{ cursor: 'pointer' }} onClick={onClose}>
          <ChevronLeftOutlined />
        </Stack>
        <Stack alignItems={'center'}>
          <Typography>
            <FormattedMessage id={'tips.sunday_pooling.dispatch.title'} />
          </Typography>
        </Stack>
        <Stack alignItems={'flex-end'}>
          <Button size="small" disabled={!canContinue} variant="contained" onClick={onClick}>
            <FormattedMessage id={'tips.sunday_pooling.dispatch.continue'} />
          </Button>
        </Stack>
      </Stack>

      <DispatchPrerequisites />

      <Stack
        sx={{ width: '100%', height: '100%', overflow: 'auto' }}
        padding="3rem 0 3rem 0"
        direction="column"
        justifyContent={'flex-start'}
        alignItems={'center'}
      >
        <Stack maxWidth={'50rem'} height="100%" justifyContent={'space-between'} spacing={3}>
          <Stack direction={'column'} spacing={4} paddingBottom={10}>
            {strategy === 'byAmounts' && (
              <StaffDispatchByAmounts
                amount={tipsToDispatch.amount}
                staffs={staffs}
                onStaffAllocationUpdated={setStaffAllocations}
              />)
            }
            {strategy === 'byHours' && (
              <StaffDispatchByHours
                amount={tipsToDispatch.amount}
                staffs={staffs}
                onStaffAllocationUpdated={setStaffAllocations}
              />)
            }
            <NonEligibleStaff staffs={staffs} />
          </Stack>
          <Stack direction={'column'} sx={{ position: 'absolute', bottom: 0, maxWidth: '50rem' }} paddingBottom={2}>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{ background: palette.primary.black, borderRadius: '1rem' }}
              padding="1rem 1.5rem 1rem 1.5rem"
            >
              <Stack direction={'row'} alignItems={'center'} gap={1}>
                {bannerContent.icon}
                <Stack direction={'column'}>
                  <Typography variant={'body1'} color="white">
                    {bannerContent.message}
                  </Typography>
                  <Typography variant={'body2'} color="textDisabled">
                    {bannerContent.subtitle}
                  </Typography>
                </Stack>
              </Stack>
              <WhiteButton disabled={!canContinue} size="small" variant="contained" onClick={onClick}>
                <FormattedMessage id={'tips.sunday_pooling.dispatch.continue'} />
              </WhiteButton>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
