import { useMemo } from 'react';
import { extractTimeFrameFilterFromURLParams } from 'src/payments/components/TimeFrameFilter';
import {
  DateRange,
  RevenueTabDatePreset,
  revenueTabDatePresets,
} from 'src/accounting/revenues/domain/RevenuesDatePresets';
import { Translation } from 'src/accounting/common/translation';
import { useLocation } from 'react-router-dom';

export const useExtractTimePeriodFromURLParams = (
  presets: Record<
  RevenueTabDatePreset,
  {
    range: DateRange;
    translation: Translation;
  }
  >,
) => {
  const location = useLocation();
  return useMemo(() => {
    return extractTimeFrameFilterFromURLParams<typeof revenueTabDatePresets>(
      location.search,
      presets,
      revenueTabDatePresets.LAST_7_DAYS_FILTER,
    );
  }, [location.search]);
};
