import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  Radio,
  RadioGroup,
  styled,
  Switch,
  Typography,
} from '@mui/material';
import { spaceUsages } from '@sundayapp/b2b-react-component-library';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { Language, supportedFilteredLanguages } from 'src/app/i18n/AllSupportedLanguage';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { CountryFlag } from 'src/components/CountryFlag';
import { SelectLanguage } from 'src/components/select/SelectLanguage';
import { ExposedMenuType } from 'src/menu/common/domain/ExposedMenuType';
import { orderingFilterConfiguration, OrderingFilterConfiguration } from 'src/menu/common/domain/MenuBusiness';
import { useAddLanguage } from 'src/menu/common/mutations/useAddLanguageMutation';
import { useBusinessMutation, useUpdateBusinessMutation } from 'src/menu/common/mutations/useBusinessMutation';
import { useRemoveLanguageMutation } from 'src/menu/common/mutations/useRemoveLanguageMutation';
import { QUERYKEY_MENU_VENUE, useGetMenuBusiness } from 'src/menu/common/queries/getMenuBusinessQuery';
import { SettingsPageContainer } from '../components/SettingsPageContainer';
import { isTaskOngoing } from 'src/menu/tasks/domain/Task';
import { useQueryClient } from '@tanstack/react-query';

const isDynamicMenuEnabled = (menuType: ExposedMenuType | undefined) => menuType === ExposedMenuType.DYNAMIC_MENU;

const MenuContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
const MenuBlock = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const LanguageItem = styled(ListItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 9px;
  max-width: 300px;
  margin: 3px;
  border: 1px solid #cbcbcb;
`;
const DeleteIcon = styled(IconButton)`
  cursor: pointer;
`;

export const MenuSettings = () => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const business = useCurrentBusinessOrThrow();
  const snackBar = useSnackbar();
  const { data: menuBusinessConfig, isLoading } = useGetMenuBusiness(business.id);
  const updateVenue = useBusinessMutation(business.id);
  const updateMenuBusiness = useUpdateBusinessMutation(business.id);
  // const { addNewLanguage, currentTaskStatus } = useAddLanguage(business.id);
  const { addNewLanguage, currentTaskStatus } = useAddLanguage(business.id);
  const removeLanguage = useRemoveLanguageMutation(business.id);

  const [checked, setChecked] = useState(isDynamicMenuEnabled(menuBusinessConfig?.exposedMenuType));
  const [selectedLanguage, setSelectedLanguage] = useState<Language>();
  const [supportedLanguages, setSupportedLanguages] = useState<Language[]>([]);
  const [addingLanguage, setAddingLanguage] = useState(false);

  useEffect(() => {
    if (menuBusinessConfig) {
      const filterOutLanguages = menuBusinessConfig.translatedLanguages.concat(menuBusinessConfig.defaultLanguage);
      const languagesSupportedByBusiness = supportedFilteredLanguages(filterOutLanguages);
      setSupportedLanguages(languagesSupportedByBusiness);
      if (!selectedLanguage) {
        setSelectedLanguage(languagesSupportedByBusiness[0]);
      }
    }
  }, [menuBusinessConfig]);

  useEffect(() => {
    setChecked(isDynamicMenuEnabled(menuBusinessConfig?.exposedMenuType));
  }, [menuBusinessConfig?.exposedMenuType]);

  useEffect(() => {
    if (currentTaskStatus && !isTaskOngoing(currentTaskStatus)) {
      setAddingLanguage(false);
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_VENUE, business.id] });
    }
  }, [currentTaskStatus]);

  const onChangeDynamicMenuStatus = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCheckedValue = event.target.checked;
    const newMenuType = newCheckedValue ? ExposedMenuType.DYNAMIC_MENU : ExposedMenuType.STATIC_MENU;

    await updateVenue
      .mutateAsync({ exposedMenuType: newMenuType })
      .then(() => {
        setChecked(newCheckedValue);
        snackBar.addNotification({
          variant: 'success',
          text: intl.formatMessage({ id: 'settings.app_settings.success_saving' }),
        });
      })
      .catch(() => {
        setChecked(!newCheckedValue);
        snackBar.addNotification({
          variant: 'error',
          text: intl.formatMessage({ id: 'settings.app_settings.error_saving' }),
        });
      });
  };

  if (isLoading) return <CircularProgress />;

  const onClickAddLanguage = async () => {
    setAddingLanguage(true);
    addNewLanguage(selectedLanguage!);
  };

  const onDeleteLanguage = (language: string) => {
    removeLanguage.mutateAsync({ language });
  };

  const updateMenuBusinessOrderingConfig = async (_: ChangeEvent<HTMLInputElement>, value: string) => {
    if (!menuBusinessConfig) {
      return;
    }

    await updateMenuBusiness
      .mutateAsync({
        orderingEnabled: menuBusinessConfig.orderingEnabled,
        orderingAllowed: menuBusinessConfig.orderingAllowed,
        filterConfiguration: value as OrderingFilterConfiguration,
        openBillEnabled: menuBusinessConfig.openBillEnabled,
      })
      .then(() => {
        snackBar.addNotification({
          variant: 'success',
          text: intl.formatMessage({ id: 'settings.app_settings.success_saving' }),
        });
      })
      .catch(() => {
        snackBar.addNotification({
          variant: 'error',
          text: intl.formatMessage({ id: 'settings.app_settings.error_saving' }),
        });
      });
  };

  return (
    <SettingsPageContainer>
      <Card sx={{ width: '100%' }}>
        <MenuContent>
          <MenuBlock>
            <Typography variant="h5" paddingBottom={spaceUsages.mediumSmall}>
              <FormattedMessage id="settings.menu.dynamic_menu" defaultMessage="Menu dynamique" />
            </Typography>

            <Box display="flex" flexDirection="row" gap={1} margin="16px 0">
              <Switch defaultChecked={checked} checked={checked} onChange={onChangeDynamicMenuStatus} />
              <Typography color="#70707B">
                <FormattedMessage id="settings.menu.dynamic_menu.enable" defaultMessage="Activer le menu dynamique" />
              </Typography>
            </Box>
          </MenuBlock>
          {menuBusinessConfig?.orderingAllowed && (
            <MenuBlock>
              <Box display="flex" flexDirection="row" gap={1} margin="16px 0">
                <FormControl>
                  <Typography variant="h5" paddingBottom={spaceUsages.mediumSmall}>
                    <FormattedMessage id="settings.menu.ordering_filter" />
                  </Typography>
                  <RadioGroup
                    defaultValue={menuBusinessConfig.filterConfiguration}
                    onChange={updateMenuBusinessOrderingConfig}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    {Object.values(orderingFilterConfiguration).map((value) => (
                      <FormControlLabel
                        key={value}
                        value={value}
                        control={<Radio />}
                        label={<FormattedMessage id={`settings.menu.ordering_filter.${value}`} />}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Box>
            </MenuBlock>
          )}
          <MenuBlock>
            <Typography variant="h5" paddingBottom={spaceUsages.mediumSmall}>
              <FormattedMessage id="settings.menu.dynamic_menus.translations" defaultMessage="Traductions" />
            </Typography>
            {supportedLanguages.length > 0 && (
              <>
                <Typography>
                  <FormattedMessage
                    id="settings.menu.dynamic_menus.add_translation"
                    defaultMessage="Ajouter une nouvelle langue :"
                  />
                </Typography>
                <Box display="flex" flexDirection="row" gap={1} margin="16px 0" alignItems="center">
                  <SelectLanguage
                    supportedLanguages={supportedLanguages}
                    currentLanguage={selectedLanguage}
                    setCurrentLanguage={setSelectedLanguage}
                  />
                  <Button
                    disabled={addingLanguage && currentTaskStatus && isTaskOngoing(currentTaskStatus)}
                    variant="contained"
                    onClick={onClickAddLanguage}
                  >
                    <FormattedMessage id="settings.menu.dynamic_menus.add" defaultMessage="Ajouter" />
                  </Button>
                  {addingLanguage && currentTaskStatus && isTaskOngoing(currentTaskStatus) && <CircularProgress />}
                </Box>
              </>
            )}
            <Typography>
              <FormattedMessage
                id="settings.menu.dynamic_menus.enabled_translations"
                defaultMessage="Traductions activées :"
              />
            </Typography>
            <List>
              {menuBusinessConfig?.translatedLanguages.map((language) => (
                <LanguageItem key={language.id}>
                  <CountryFlag language={language} />
                  <DeleteIcon size="large" onClick={() => onDeleteLanguage(language.id)}>
                    <DeleteOutlineIcon fontSize="large" />
                  </DeleteIcon>
                </LanguageItem>
              ))}
            </List>
          </MenuBlock>
        </MenuContent>
      </Card>
    </SettingsPageContainer>
  );
};
