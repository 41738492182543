import { DateRange, revenueTabDatePresets } from 'src/accounting/revenues/domain/RevenuesDatePresets';
import { ValuesOf } from 'src/utils/typescript/valuesOf';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import moment, { Moment } from 'moment-timezone';
import { Box, SxProps } from '@mui/material';
import { DateTimePicker, IconButton } from '@sundayapp/b2b-react-component-library';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { PeriodFilter } from 'src/accounting/payout/pages/filter/PeriodFilter';
import { Translation } from 'src/accounting/common/translation';
import { datadogLogs } from '@datadog/browser-logs';
import { LocalisationKey } from 'src/lang/en';

export type Period<T extends string> = {
  filter: T;
  dateRange: DateRange;
};

export type DatePreset = {
  range: DateRange;
  translation: Translation;
};

export type DatePresets<T extends Record<string, string>> = Record<ValuesOf<T>, DatePreset>;
type PeriodFilterProps<T extends Record<string, string>> = {
  datePresets: DatePresets<T>;
  period: Period<ValuesOf<T>>;
  timezone?: string;
  sx?: SxProps;
  label?: { key: LocalisationKey, defaultMessage: string };
};

const PERIOD_FILTER_WIDTH = 280;

const stringToDate = (str: string | null, defaultDate: Moment, timezone: string): Moment => (str ? moment.tz(str, timezone) : defaultDate);

export const URL_DATE_FORMAT = 'yyyy-MM-DDTHH:mm:ss';
export const extractTimeFrameFilterFromURLParams = <T extends Record<string, string>>(
  search: string,
  datePresets: DatePresets<T>,
  defaultValue: ValuesOf<T>): Period<ValuesOf<T>> => {
  const params = new URLSearchParams(search);
  const tz = params.get('tz') || Intl.DateTimeFormat()
    .resolvedOptions().timeZone;
  const startDate = stringToDate(params.get('startDate'), moment.tz(tz), tz);
  const endDate = stringToDate(params.get('endDate'), moment.tz(tz), tz)
    .second(59);
  const filter = params.get('preset') as ValuesOf<T> || defaultValue;
  return {
    filter,
    dateRange: filter === revenueTabDatePresets.CUSTOM
      ? {
        startDate,
        endDate,
      }
      : datePresets[filter].range,
  };
};

export const TimeFrameFilter = function <T extends Record<string, string>>({
  datePresets,
  period,
  timezone,
  sx = { minWidth: PERIOD_FILTER_WIDTH },
  label,
}: PeriodFilterProps<T>) {
  const [isDatePickerOpened, setIsDatePickerOpened] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const onCustomDateRangeSelectionChange = (dates: Date[]) => {
    setIsDatePickerOpened(false);
    const datePickerStartDateInput = moment(dates[0])
      .format(URL_DATE_FORMAT);
    const datePickerEndDateInput = moment(dates[1])
      .format(URL_DATE_FORMAT);

    const urlParams = new URLSearchParams(location.search);
    urlParams.set('preset', revenueTabDatePresets.CUSTOM);
    urlParams.set('startDate', datePickerStartDateInput);
    urlParams.set('endDate', datePickerEndDateInput);
    if (timezone) {
      urlParams.set('tz', timezone);
    }
    location.search = urlParams.toString();
    navigate(location);
  };

  const onDatePickerClosed = () => {
    setIsDatePickerOpened(false);
  };

  const onDatePresetChange = (periodFilterValue: string) => {
    if (periodFilterValue === revenueTabDatePresets.CUSTOM) {
      setIsDatePickerOpened(true);
    } else {
      setIsDatePickerOpened(false);
      const urlParams = new URLSearchParams(location.search);
      urlParams.set('preset', periodFilterValue);
      urlParams.delete('startDate');
      urlParams.delete('endDate');
      urlParams.delete('tz');
      location.search = urlParams.toString();
      navigate(location);
    }
  };

  const periodFilterOptions = (Object.keys(datePresets) as ValuesOf<T>[])
    .map((key) => ({
      filter: key,
      translation: datePresets[key].translation,
    }));

  const getCustomValues = () => {
    try {
      return [
        new Date(period.dateRange.startDate.clone()
          .startOf('day')
          .format('yyyy-MM-DD HH:mm')),
        new Date(period.dateRange.endDate.clone()
          .endOf('day')
          .format('yyyy-MM-DD HH:mm')),
      ];
    } catch (e) {
      datadogLogs.logger.error('Error while parsing date range in TimeFrameFilter', {
        period,
        datePresets,
        timezone,
        location,
      }, e as Error);
      return [new Date(moment()
        .startOf('day')
        .format('yyyy-MM-DD HH:mm')),
      new Date(moment()
        .endOf('day')
        .format('yyyy-MM-DD HH:mm'))];
    }
  };
  return (
    <>
      <Box display="flex" sx={sx} gap={1}>
        {/* select period */}
        <Box display="flex" sx={sx}>
          <PeriodFilter
            periodFilter={period.filter}
            setCurrentPeriodFilter={onDatePresetChange}
            filterValues={periodFilterOptions}
            sx={sx}
            label={label}
          />
        </Box>

        {/* calendar icon of the date picker */}
        {period.filter === revenueTabDatePresets.CUSTOM && (
          <Box display="flex" alignItems="center">
            <IconButton
              size="small"
              onClick={() => setIsDatePickerOpened(!isDatePickerOpened)}
              variant="default"
            >
              <CalendarMonthIcon direction="left" />
            </IconButton>
          </Box>
        )}
      </Box>

      {/* date picker  */}
      {
        isDatePickerOpened && (
          <DateTimePicker
            onClose={onDatePickerClosed}
            onApply={onCustomDateRangeSelectionChange}
            value={getCustomValues()}
          />
        )
      }
    </>
  );
};
