import { Page } from '@templates/PageTemplate';
import React, { useState } from 'react';
import { Box, Card, CardContent, CircularProgress, Typography } from '@mui/material';
import {
  ExportEnterprisePayoutArgs,
  exportEnterprisePayouts,
} from 'src/multi-locations/infrastructure/enterpriseGateway';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Period, revenuesDatePresets } from 'src/accounting/revenues/domain/RevenuesDatePresets';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { relationTypes } from 'src/auth/domain/user';
import { BusinessId } from 'src/business/domain/Business';
import { TimeFrameFilter } from 'src/payments/components/TimeFrameFilter';
import { useBusinessQuery } from 'src/business/hooks/useBusinessQuery';
import { businessIdsForMultiLocations } from 'src/multi-locations/domain/User';
import { useExtractTimePeriodFromURLParams } from 'src/multi-locations/hooks/extractPeriodFromURLParams';
import { prepareDownload, usePrepareLogAndNotifyError } from 'src/multi-locations/utils/download';
import { LocalisationKey } from 'src/lang/en';
import { DownloadButton } from 'src/multi-locations/components/DownloadButton';
import { useBusinessesQuery } from 'src/business/hooks/useBusinessesQuery';
import { ampli } from 'src/ampli';
import { datadogRum } from '@datadog/browser-rum';
import { EnterpriseSelector } from 'src/multi-locations/components/EnterpriseSelector';
import { LocationPreview } from 'src/multi-locations/pages/invoices/MultiLocationsInvoices';

// const LocationPreview = () => {
//   return (
//     <Box display={'flex'} alignItems={'center'} gap={2}>
//       <MultiLocationsLogo iconSize={32} />
//       <Typography style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} variant={'h6'}>
//         <FormattedMessage id={'enterprise.all_locations' as LocalisationKey} defaultMessage={'All locations'} />
//       </Typography>
//     </Box>
//   );
// };

const formatDate = (date: Date) => ({
  date: date.toLocaleDateString(navigator.language, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }),
  time: date.toLocaleTimeString(navigator.language, {
    hour: '2-digit',
    minute: '2-digit',
  }),
});

const DatePreview = ({ period, timezone }: { period: Period; timezone: string }) => {
  const startDate = formatDate(period.dateRange.startDate.toDate());
  const endDate = formatDate(period.dateRange.endDate.toDate());
  return (
    <Typography
      style={{ opacity: 0.5, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
      variant={'body1'}
    >{`${startDate.date} ${startDate.time} - ${endDate.date} ${endDate.time} (${timezone})`}</Typography>
  );
};

export const MultiLocationsPayouts = () => {
  const user = useAuthenticatedUserOrThrow();
  const businessIds = businessIdsForMultiLocations(user, relationTypes.can_access_financials);
  const { data: businesses } = useBusinessesQuery(businessIds);
  const { data: firstBusiness, isLoading } = useBusinessQuery(businessIds[0] as BusinessId);
  const datePresets = revenuesDatePresets(firstBusiness?.timezone || 'UTC');
  const intl = useIntl();
  const [inProgress, setInProgress] = useState<boolean>(false);
  const period: Period = useExtractTimePeriodFromURLParams(datePresets);
  const [selectedBusinesses, setSelectedBusinesses] = useState<BusinessId[]>(businessIds);

  const download = prepareDownload<ExportEnterprisePayoutArgs>({
    onDownloadStarted: () => {
      ampli.enterprisePayoutsDownloaded({
        transactionPeriodStart: period.dateRange.startDate.toISOString(),
        transactionPeriodEnd: period.dateRange.endDate.toISOString(),
        transactionPeriodOption: period.filter,
        isDefaultTransactionPeriod:
          period.dateRange.startDate.date() === datePresets.LAST_7_DAYS_FILTER.range.startDate.date() &&
          period.dateRange.endDate.date() === datePresets.LAST_7_DAYS_FILTER.range.endDate.date(),
      });
      datadogRum.addAction('enterprise_payouts_downloaded');
      setInProgress(true);
    },
    fetchBlobWithMetadata: exportEnterprisePayouts,
    onDownloadFinished: () => setInProgress(false),
    onError: usePrepareLogAndNotifyError(
      intl.formatMessage({
        id: 'accounting.payout_tab.payout_export_loading_error',
        defaultMessage: 'Oops, unable to fetch sunday payouts export',
      }),
    ),
    filename: `${intl.formatMessage({ id: 'accounting.payout_tab.export_file' })}.csv`,
  });

  if (isLoading || !firstBusiness || !businesses) {
    return <CircularProgress />;
  }

  return (
    <Page
      hasHeadTitle={{
        preLogoText: [
          {
            id: 'accounting.payout_tab',
          },
        ],
        postLogoText: [
          {
            id: 'sunday',
          },
        ],
        variant: 'h5',
        hasBadge: false,
        badgeLabel: '',
        showTitle: true,
      }}
      noBanner={true}
    >
      <Box
        style={{ marginTop: '32px' }}
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        gap={2}
      >
        <EnterpriseSelector businesses={businesses} setSelectedLocations={setSelectedBusinesses} />
        <TimeFrameFilter
          datePresets={datePresets}
          period={period}
          timezone={firstBusiness.timezone}
          label={{
            key: 'enterprise.accounting.payout_tab.filter_label' as LocalisationKey,
            defaultMessage: 'Transaction period',
          }}
        />
      </Box>
      <Box
        style={{ marginTop: '32px' }}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        gap={2}
      >
        <Card>
          <CardContent data-testid={'enterprise-payouts-preview-card'}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <LocationPreview
                data-testid={'enterprise-payouts-preview-card-locations'}
                businesses={businesses.filter((b) => selectedBusinesses.includes(b.id))}
                size={businesses.length}
              />
              <DatePreview
                timezone={firstBusiness.timezone}
                period={period}
                data-testid={'enterprise-payouts-preview-card-period'}
              />
              {selectedBusinesses.length > 0 && (
                <DownloadButton
                  download={() => download({ business_ids: selectedBusinesses, range: period.dateRange })}
                  inProgress={inProgress}
                  testDataId={'enterprise-payouts-download-button'}
                  labelMessageId={'accounting.revenue_tab.panel.downloadPayouts'}
                  defaultMessage={'Download payouts'}
                />
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Page>
  );
};
