import { useFormContext } from 'react-hook-form';
import { Address } from 'src/payments/components/Checkout/CheckoutOnboardingFR/BusinessDetails/Address';
import React from 'react';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const PrimaryAddress = () => {
  const { formState: { errors }, register } = useFormContext<CheckoutOnboardingInformationDraft>();
  const primaryAddressErrors = errors.primaryAddress;
  const intl = useIntl();
  return <Address
    addressType={'primaryAddress'}
    addressTitle={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.primaryAddress' })}
    addressErrors={{
      addressLine1: primaryAddressErrors?.addressLine1,
      zip: primaryAddressErrors?.zip,
      city: primaryAddressErrors?.city,
      state: primaryAddressErrors?.state,
    }} register={register} />;
};
