import { Page } from '@templates/PageTemplate';
import React, { useEffect, useState } from 'react';
import { ampli } from 'src/ampli';
import { Box, Card, CardContent, CircularProgress, Typography } from '@mui/material';
import {
  exportEnterpriseInvoices,
  ExportEnterpriseInvoicesArgs,
} from 'src/multi-locations/infrastructure/enterpriseGateway';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { relationTypes } from 'src/auth/domain/user';
import { businessIdsForMultiLocations } from 'src/multi-locations/domain/User';
import { prepareDownload, usePrepareLogAndNotifyError } from 'src/multi-locations/utils/download';
import { LocalisationKey } from 'src/lang/en';
import { DownloadButton } from 'src/multi-locations/components/DownloadButton';
import { useBusinessesQuery } from 'src/business/hooks/useBusinessesQuery';
import { earliestBusinessCreationYear } from 'src/multi-locations/domain/Businesses';
import { yearsUntilCurrentYear } from 'src/multi-locations/domain/dateRange';
import { PeriodFilter } from 'src/multi-locations/components/PeriodFilter';
import { MultiLocationsLogo } from 'src/multi-locations/components/MultiLocationsLogo';
import { datadogRum } from '@datadog/browser-rum';
import { EnterpriseSelector } from 'src/multi-locations/components/EnterpriseSelector';
import { Business, BusinessId } from 'src/business/domain/Business';

export const LocationPreview = ({ businesses, size }: { businesses: Business[]; size: number }) => {
  const businessesNames = businesses.map((b) => b.name).join(',');
  return (
    <Box display={'flex'} alignItems={'center'} gap={2}>
      <MultiLocationsLogo iconSize={32} />
      {businesses.length === size && (
        <Typography style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} variant={'h6'}>
          <FormattedMessage id={'enterprise.all_locations' as LocalisationKey} defaultMessage={businessesNames} />
        </Typography>
      )}
      {businesses.length < size && (
        <Typography style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} variant={'h6'}>
          {businessesNames}
        </Typography>
      )}
    </Box>
  );
};

const DatePreview = ({ year }: { year: string }) => {
  return (
    <Typography
      style={{ opacity: 0.5, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
      variant={'body1'}
    >
      {year}
    </Typography>
  );
};

export const MultiLocationsInvoices = () => {
  const user = useAuthenticatedUserOrThrow();
  const businessIds = businessIdsForMultiLocations(user, relationTypes.can_access_financials);
  const intl = useIntl();
  const [inProgress, setInProgress] = useState<boolean>(false);
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear.toString());
  const [selectedBusinesses, setSelectedBusinesses] = useState<BusinessId[]>(businessIds);
  let years: string[] = [selectedYear];

  const onYearChanged = (year: string) => {
    setSelectedYear(year);
  };

  const { data: businesses } = useBusinessesQuery(
    businessIdsForMultiLocations(user, relationTypes.can_access_financials),
  );

  useEffect(() => {
    years = businesses
      ? yearsUntilCurrentYear({ fromYear: earliestBusinessCreationYear(businesses) }).map((y) => y + '')
      : years;
  }, [businesses]);

  const download = prepareDownload<ExportEnterpriseInvoicesArgs>({
    onDownloadStarted: () => {
      ampli.enterpriseInvoicesDownloaded({
        billingYear: selectedYear,
        isDefaultBillingYear: selectedYear === currentYear.toString(),
      });
      datadogRum.addAction('enterprise_invoices_downloaded');
      setInProgress(true);
    },
    fetchBlobWithMetadata: exportEnterpriseInvoices,
    onDownloadFinished: () => setInProgress(false),
    onError: usePrepareLogAndNotifyError(
      intl.formatMessage({
        id: 'accounting.invoice_tab.invoice_loading_error',
        defaultMessage: 'Oops, unable to fetch list of sunday invoices',
      }),
    ),
    filename: `invoices_${selectedYear}.zip`,
  });

  if (!businesses) {
    return <CircularProgress />;
  }

  return (
    <Page
      hasHeadTitle={{
        preLogoText: [
          {
            id: 'accounting.invoice_tab',
          },
        ],
        postLogoText: [
          {
            id: 'sunday',
          },
        ],
        variant: 'h5',
        hasBadge: false,
        badgeLabel: '',
        showTitle: true,
      }}
      noBanner={true}
    >
      <Box
        style={{ marginTop: '32px' }}
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        gap={2}
      >
        <EnterpriseSelector businesses={businesses} setSelectedLocations={setSelectedBusinesses} />
        <PeriodFilter years={years} selectedYear={selectedYear} onYearChanged={onYearChanged} />
      </Box>
      <Box
        style={{ marginTop: '32px' }}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        gap={2}
      >
        <Card>
          <CardContent data-testid={'enterprise-invoices-preview-card'}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <LocationPreview
                businesses={businesses.filter((b) => selectedBusinesses.includes(b.id))}
                size={businesses.length}
                data-testid={'enterprise-invoices-preview-card-locations'}
              />
              <DatePreview year={selectedYear} data-testid={'enterprise-invoices-preview-card-period'} />
              {selectedBusinesses.length > 0 && (
                <DownloadButton
                  download={() => download({ business_ids: selectedBusinesses, year: selectedYear })}
                  inProgress={inProgress}
                  testDataId={'enterprise-invoices-download-button'}
                  labelMessageId={'accounting.invoice_tab.download'}
                  defaultMessage={'Download payouts'}
                />
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Page>
  );
};
