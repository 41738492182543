import { useIntl } from 'src/app/i18n/TypedIntl';
import React, { useState } from 'react';
import { Dropdown } from '@sundayapp/b2b-react-component-library';
import { IntlShape } from 'react-intl/src/types';
import { useUpdateVenueDetails } from '../../../../hook';
import { SettingPanel } from 'src/ordering/common/components/Settings/SettingPanel';
import { SettingExpandablePanel } from 'src/ordering/common/components/Settings/SettingExpandablePanel';
import { SettingLine } from 'src/ordering/common/components/Settings/SettingLine';
import { VenueDetails } from '../../../../types';
import { usePushNotification } from 'src/ordering/common/components/Notifications.hook';
import { Switch } from '@mui/material';

type GroupingVenueSettingProps = {
  venue: VenueDetails;
  setVenue: (venue: VenueDetails) => void;
  hideForShadowEnrollment: boolean;
};

type SettingValues = {
  enableOrderGrouping: boolean;
  orderGroupingTimeout: number;
  orderGroupingEnableSendNow?: boolean;
};

type WaitingTimeOption = {
  id: number;
  label: string;
};

const waitingTimeOpt = (intl: IntlShape): WaitingTimeOption[] => {
  const result: WaitingTimeOption[] = [];

  result.push({
    id: 60,
    label: intl.formatMessage({ id: 'venue.enableGrouping.minute', defaultMessage: '1 minute' }),
  });

  // eslint-disable-next-line no-plusplus
  for (let i = 2; i <= 30; i++) {
    result.push({
      id: i * 60,
      label: intl.formatMessage(
        { id: 'venue.enableGrouping.minutes', defaultMessage: '{minutes} minutes' },
        { minutes: i },
      ),
    });
  }

  return result;
};

export const GroupingVenueSetting = ({ venue, setVenue, hideForShadowEnrollment }: GroupingVenueSettingProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [, updateVenueDetails] = useUpdateVenueDetails(venue.id);
  const [isLoading, makeLoading] = useState<boolean>(false);
  const [expanded, setExpansion] = useState<boolean>(false);

  const currentSettings: SettingValues = {
    enableOrderGrouping: venue.enableOrderGrouping,
    orderGroupingTimeout: venue.orderGroupingTimeout,
    orderGroupingEnableSendNow: venue.orderGroupingEnableSendNow,
  };

  const venueUpdated = async (values: SettingValues) => {
    makeLoading(true);
    await updateVenueDetails({
      ...values,
    });
    setVenue({
      ...venue,
      ...values,
    });
    pushNotification(intl.formatMessage({ id: 'venue.settings.updated', defaultMessage: 'venue settings updated' }));
    makeLoading(false);
  };

  const options: WaitingTimeOption[] = waitingTimeOpt(intl);

  const selectedOption = options.find((option) => option.id === venue.orderGroupingTimeout) || options[0];

  const updateOrderGrouping = async (activated: boolean) => {
    await venueUpdated({ ...currentSettings, enableOrderGrouping: activated });

    if (activated) {
      setExpansion(true);
    }
  };

  const updateWaitingTimeFromOption = async (waitingTimeOption: WaitingTimeOption) => {
    await venueUpdated({ ...currentSettings, orderGroupingTimeout: waitingTimeOption.id });
  };

  const updateSendNowOption = async (activated: boolean) => {
    await venueUpdated({ ...currentSettings, orderGroupingEnableSendNow: activated });
  };

  return (
    <SettingPanel
      title={intl.formatMessage({
        id: 'venue.enableGrouping',
        defaultMessage: 'group ordering',
        description: 'Settings to group the orders before sending them to the POS',
      })}
      description={intl.formatMessage({
        id: 'venue.enableGroupingDescription',
        defaultMessage: 'Group the orders of your table before sending them',
        description: 'Description of the order grouping',
      })}
      toggleValue={venue.enableOrderGrouping}
      toggleOnChange={updateOrderGrouping}
      disabled={isLoading}
    >
      <SettingExpandablePanel visible={venue.enableOrderGrouping} expanded={expanded} expandChange={setExpansion}>
        <SettingLine
          title={intl.formatMessage({
            id: 'venue.enableGrouping.timeout',
            defaultMessage: 'how long does it take to order?',
          })}
          description={intl.formatMessage({
            id: 'venue.enableGrouping.timeoutHelper',
            defaultMessage:
            // eslint-disable-next-line max-len
              'Here you can set the time you want customers to wait after the first order has been placed. This only appears if other people have scanned the QR device at the same table. By default, we set this time at 3 minutes but you can change it up to 30 minutes if you detect that orders still arrive separately.',
            description: 'explain the role of the timeout for the order grouping',
          })}
        >
          <Dropdown
            size="small"
            options={options}
            value={selectedOption}
            labelField="label"
            onChange={updateWaitingTimeFromOption}
          />
        </SettingLine>
        {!hideForShadowEnrollment && <SettingLine
          title={intl.formatMessage({
            id: 'venue.enableGrouping.enableSendNow',
            defaultMessage: 'Do you want the send now button?',
          })}
          description={intl.formatMessage({
            id: 'venue.enableGrouping.enableSendNowHelper',
            defaultMessage:
              'Here you can allow the customer to send immediately the order. By default, the send now button is shown',
            description: 'enable / disable the send now button for the order grouping',
          })}
        >
          <Switch onChange={(_, updatedValue: boolean) => updateSendNowOption(updatedValue)}
                  checked={venue.orderGroupingEnableSendNow ? venue.orderGroupingEnableSendNow : false} />
        </SettingLine>
        }
      </SettingExpandablePanel>
    </SettingPanel>
  );
};
