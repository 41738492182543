import { Staff } from 'src/tips/dispatch/model/Staff';
import { TipsAllocations } from 'src/tips/dispatch/model/TipsAllocation';
import { divideMoney, money, Money, multiplyMoney, roundToCents } from '@sundayapp/web-money';

export type StaffAllocation = {
  staffId: string;
  staffName: string;
  allocation: Money;
};

export type StaffAllocationByHours = StaffAllocation & {
  weeklyWorkedHours: number;
};

export const staffName = (staff: Staff) => {
  if (staff.firstName !== '' || staff.lastName !== '') return `${staff.firstName} ${staff.lastName}`;
  if (staff.email !== '') return staff.email;
  if (staff.phoneNumber !== '') return staff.phoneNumber;
  return JSON.stringify(staff);
};

export const eligibleStaff = (staff: Staff[]): Staff[] => staff.filter((s) => s.canReceiveTips);
export const nonEligibleStaff = (staff: Staff[]): Staff[] => staff.filter((s) => !s.canReceiveTips);

export const toStaffAllocationsByHours = (balance: Money, staffs: Staff[]): StaffAllocationByHours[] =>
  staffs
    .sort((a, b) => a.firstName.localeCompare(b.firstName))
    .map((s) => ({
      staffId: s.id,
      staffName: staffName(s),
      weeklyWorkedHours: 40,
      allocation: { amount: 0, currency: balance.currency },
    }));

export const distributeByWorkingHours = (
  balance: Money,
  staffAllocations: StaffAllocationByHours[],
): StaffAllocationByHours[] => {
  if (staffAllocations.length === 0) return [];
  const totalWeeklyWorkedHours = staffAllocations.reduce((acc, a) => acc + a.weeklyWorkedHours, 0);
  const newAllocations = staffAllocations.map((a) => ({
    ...a,
    allocation:
      totalWeeklyWorkedHours === 0
        ? money(0, balance.currency)
        : roundToCents(divideMoney(multiplyMoney(balance, a.weeklyWorkedHours), totalWeeklyWorkedHours)),
  }));

  if (totalWeeklyWorkedHours === 0) return newAllocations;

  // redistribute rounding remainder among staff members
  let remainder = balance.amount - newAllocations.reduce((acc, a) => acc + a.allocation.amount, 0);
  const noOneHasBeenAllocatedTips = !newAllocations.some((a) => a.allocation.amount > 0);

  if (remainder > 0) {
    let counter = 0;
    while (remainder > 0) {
      if (noOneHasBeenAllocatedTips || newAllocations[counter].allocation.amount > 0) {
        newAllocations[counter].allocation.amount += 1000;
        remainder -= 1000;
      }
      counter++;
    }
  }

  if (remainder < 0) {
    let counter = 0;
    while (remainder < 0) {
      if (noOneHasBeenAllocatedTips || newAllocations[counter].allocation.amount > 0) {
        newAllocations[counter].allocation.amount -= 1000;
        remainder += 1000;
      }
      counter++;
    }
  }

  return newAllocations;
};

export const distributeByWorkingHoursForEligibleStaffs = (amount: Money, staffs: Staff[]) =>
  distributeByWorkingHours(amount, toStaffAllocationsByHours(amount, eligibleStaff(staffs)));

export const toTipsAllocations = (staffAllocations: StaffAllocation[]): TipsAllocations =>
  staffAllocations.map((a) => ({ staffId: a.staffId, amount: a.allocation }));

export const keepOnlyStaffWithTips = (staffAllocations: StaffAllocation[]): StaffAllocation[] =>
  staffAllocations.filter((a) => a.allocation.amount > 0);

export const hasTipsAllocated = (staffAllocations: StaffAllocation[]): boolean =>
  !!staffAllocations.find((a) => a.allocation.amount > 0);

export const distributeByAmountsForEligibleStaffs = (balance: Money, staffs: Staff[]): StaffAllocation[] =>
  eligibleStaff(staffs).map((staff) => ({
    staffId: staff.id,
    staffName: staffName(staff),
    allocation: money(0, balance.currency),
  }));
